import React, { useState } from 'react'
import styles from "./form.module.css"
import close from "../../Assets/close.svg"
import logop from "../../Assets/My Burgo Color Logo.png"
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import hearticon from "../../Assets/Heart.svg";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { open_api } from '../../Utils/network';

const FormModal = ({ handleClose, handelalert }) => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: null,
  })
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  // const [sOpne, setSOpne] = useState(false)

  const handleChange = (e, type, selectName,) => {
    // e.preventdefault();

    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });

    // Reset validation errors when user starts typing
    if (name === "name") {
      setNameError(false);
    } else if (name === "email") {
      setEmailError(false);
    } else if (name === "phone") {
      setPhoneError(false);
    }
    // console.log(userDetails);
  }
  // Regular expressions for phone number and email validation
  const phoneRegex = /^\d{10}$/; // Assumes a 10-digit phone number format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;



  const getuserdetails = (e) => {
    e.preventDefault();
    // Perform form submission or other actions with the form data
    // setSOpne(false)
    // Validate required fields
    if (userDetails.name === "") {
      setNameError(true);
      return;
    }
    if (userDetails.email === "" || !emailRegex.test(userDetails.email)) {
      setEmailError(true);
      return;
    }
    if (userDetails.phone === "" || !phoneRegex.test(userDetails.phone)) {
      setPhoneError(true);
      return;
    }
    // console.log(userDetails, "getuserdetails");

    open_api
      .post(`home/earlyinterest/`, userDetails)
      .then((res) => {
        // console.log(res?.data?.data, "getuserdetails");
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setUserDetails({
            name: "",
            email: "",
            phone: null,
          })
          handleClose();
          handelalert()
        }

      })
      .catch((err) => {
        console.log(err);
      })
  }
  return (
    <>
      <div >
        <div className={styles.close}>
          <img src={close} alt='close' onClick={handleClose} />
        </div>
        <div className={styles.formBox}>
          <div className={styles.logoContainer}>
            <img src={logop} alt='logo' />
          </div>
          <h3 className={styles.normalText}>Be the first one to get access to India's first parenting club and get an exclusive early access </h3>
          <div>
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: '10px 0', width: '100%' },
              }}
              noValidate
              autoComplete="off"
            >
              <div>
                <TextField
                  required
                  name='name'
                  error={nameError}
                  value={userDetails?.name}
                  onChange={handleChange}
                  id="outlined-error-helper-text outlined-required"
                  label="Full Name"
                  helperText={
                    phoneError ? "Enter your Full name" : ""
                  }

                // defaultValue="Hello World"
                // helperText="Incorrect entry."
                />
              </div>
              <div>
                <TextField
                  // required
                  error={emailError}
                  value={userDetails?.email}
                  name='email'
                  onChange={handleChange}
                  id="outlined-error-helper-text outlined-required"
                  label="Email ID"
                // defaultValue="Hello World"
                // helperText="Incorrect entry."
                />
              </div>
              <div>
                <TextField
                  required
                  type='Number'
                  error={phoneError}
                  value={userDetails?.phone}
                  name='phone'
                  onChange={handleChange}
                  id="outlined-error-helper-text outlined-required"
                  label="Mobile No"
                  // defaultValue="Hello World"
                  // helperText="Incorrect entry."
                  helperText={
                    phoneError ? "Phone Number is required" : ""
                  }
                />
              </div>

              <Button className={styles.notifyme} onClick={getuserdetails} variant='contained' >
                join the list
              </Button>
            </Box>
          </div>
          <div className={styles.heartTop}>
            <img src={hearticon} alt="heart-top" />
          </div>
          <div className={styles.heartbuttom}>
            <img src={hearticon} alt="heart-top" />
          </div>
        </div>

      </div>
      
    </>
  )
}

export default FormModal