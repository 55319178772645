import React, { useEffect, useState } from 'react'
import styles from './InnerBlog.module.css'
import BlogInfo from '../../Components/BlogInfo/BlogInfo'
import InnerCards from '../../Components/InnerCards/InnerCards'
import SpecialitiesCard from '../../Components/SpecialitiesCard/SpecialitiesCard'
import { open_api } from '../../Utils/network'
import { useParams } from 'react-router-dom'

function InnerBlog() {
    const [blogData, setBlogData] = useState();
    const [blogs, setBlogs] = useState([])
    
    const { id } = useParams();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, [id]);

      useEffect(() => {
        if(id){

            blogDetailes(id);
            // getBlogComments(id);
        }
      },[id])

      const blogDetailes = (id) => {
        open_api
        .get(`blog/blog_pages/${id}/`)
        .then((res) => {
            // console.log(res?.data);
            setBlogData(res?.data)
        })
        .catch((err) => {
            console.log(err);
        })
      }


    //   get blog listing
  

    useEffect(() => {
        getBlogDetails();
    }, []);



    const getBlogDetails = () => {
        open_api
            .get(`blog/blog_pages?fields=id%2Ctitle%2Cpublished_date%2Cintro%2Cheader_image_url%2Ctags%2Cread_time%2Cauthors&type=blogs.BlogPage`)
            .then((res) => {
                // console.log(res?.data?.items, "getBlogDetails");
                if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
                    setBlogs(res?.data?.items)

                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

      
    return (
        <div className={styles.container}>

            <section>

                <BlogInfo data={blogData} />
            </section>
            <section className={styles.specilistCard}>
                <h1 className={styles.innerh1}>RECOMMENDED COURSES</h1>
                <SpecialitiesCard data={data} />

            </section>
            <section className={styles.innerCards}>
                <h3>YOU MAY ALSO LIKE</h3>
                <h2>TRENDING</h2>
                <div className={styles.innerCardContainer}>
                    {blogs.map((item) => {
                        return (
                            <InnerCards data={item}/>
                        )
                    })}
                </div>
            </section>
        </div>
    )
}

export default InnerBlog
const data = [
    { id: 1, title: "Baby sleep and needs" }
    ,
    { id: 2, title: "Baby sleep and needs" },
    { id: 3, title: "Baby sleep and needs" },
    { id: 4, title: "Baby sleep and needs" },
    {
        id: 5, title: "Baby sleep and needs"

    }]

