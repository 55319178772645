import React, { useState, useEffect } from "react";
import { Calendar, Search } from "lucide-react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./RevenueDashboard.css";
import { open_api_with_user_token } from "../../Utils/network";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import comingsoon from "../../Assets/comingsoon.svg";
import LoadingOverlay from "../Dialog/LoadingOverlay";

const Overview = () => {
  return (
    <div className="comingsoon-container">
      <div className="comingsoon-text">
        <div className="not-found-image">
          <img src={comingsoon} alt="coming soon" />
        </div>
        <h1>Coming soon</h1>
      </div>
    </div>
  );
};

const Transactions = ({
  searchType,
  searchQuery,
  dateRange,
  customDateRange,
  setCustomDateRange,
  currentPage,
  data,
  totalCount,
  maxPages,
  handleSearchQueryChange,
  handleSearchTypeChange,
  handleDateRangeChange,
  handlePreviousPage,
  handleNextPage,
  startDate,
  endDate,
  renderTable,
}) => {
  return (
    <>
      <div className="filters">
        <div className="search-container">
          <select value={searchType} onChange={handleSearchTypeChange}>
            <option value="workshop">Workshop</option>
            <option value="workshopcoupon">Coupon code</option>
            <option value="session">Session</option>
            <option value="transaction">Transaction Id</option>
            <option value="user">Username</option>
          </select>
          <div className="search-input-container">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchQueryChange}
              placeholder="Search"
            />
            <Search size={20} className="search-icon" />
          </div>
        </div>

        <div className="date-range">
          <Calendar size={20} className="calendar-icon" />
          <select value={dateRange} onChange={handleDateRangeChange}>
            <option>Today</option>
            <option>Yesterday</option>
            <option>Last 7 Days</option>
            <option>Last 30 Days</option>
            <option>Last 90 Days</option>
            <option>Custom</option>
          </select>
          {dateRange === "Custom" && (
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              onChange={(update) => setCustomDateRange(update)}
              isClearable={true}
              placeholderText="Select a date range"
              maxDate={new Date()}
            />
          )}
        </div>
      </div>

      {renderTable()}

      <div className="paginationRevenue">
        <span>
          Showing page {currentPage} of {maxPages}, total {totalCount} results
        </span>
        <div style={{ paddingTop: "2%" }}>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </button>
          <button onClick={handleNextPage} disabled={currentPage === maxPages}>
            Next
          </button>
        </div>
      </div>
    </>
  );
};

const RevenueDashboard = () => {
  const [activeTab, setActiveTab] = useState("Overview");
  const [searchType, setSearchType] = useState("workshop");
  const [searchQuery, setSearchQuery] = useState("");
  const [dateRange, setDateRange] = useState("Last 90 Days");
  const [customDateRange, setCustomDateRange] = useState([null, null]);
  const [startDate, endDate] = customDateRange;
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [maxPages, setMaxPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/profile/revenue") {
      navigate("/profile/revenue/overview");
    }
  }, [location, navigate]);

  useEffect(() => {
    if (activeTab === "Transactions") {
      fetchData(currentPage);
    }
  }, [
    activeTab,
    searchType,
    searchQuery,
    dateRange,
    customDateRange,
    currentPage,
  ]);

  const fetchData = async (page) => {
    let apiURL = `/payment/expert-revenue/?page=${page}`;
    const bearerToken = localStorage.getItem("bearerToken");

    const queryParams = [];
    if (searchQuery) {
      queryParams.push(`search=${searchQuery}`);
      queryParams.push(`search_type=${searchType}`);
    }

    if (dateRange === "Custom" && startDate && endDate) {
      queryParams.push(`created__gte=${formatDate(startDate)}`);
      queryParams.push(`created__lte=${formatDate(endDate)}`);
    } else if (dateRange !== "Custom") {
      const [start, end] = getDateRange(dateRange);
      queryParams.push(`created__gte=${formatDate(start)}`);
      queryParams.push(`created__lte=${formatDate(end)}`);
    }

    if (queryParams.length > 0) {
      apiURL += "&" + queryParams.join("&");
    }

    const response = await open_api_with_user_token(bearerToken).get(apiURL);
    setData(response?.data?.data);
    setTotalCount(response?.data?.total_count);
    setMaxPages(response?.data?.max_pages);
    setLoading(false);
  };

  const handleDateRangeChange = async (e) => {
    setLoading(true);
    setDateRange(e.target.value);
    setCurrentPage(1);
    await fetchData(1);
  };

  const handleCustomDateRangeChange = async (update) => {
    setLoading(true);
    setCustomDateRange(update);
    setCurrentPage(1);
    if (update[0] && update[1]) {
      await fetchData(1);
    } else {
      setLoading(false);
    }
  };

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
    setCurrentPage(1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < maxPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const getDateRange = (range) => {
    const today = new Date();
    let startDate = new Date(today);
    let endDate = new Date(today);

    switch (range) {
      case "Today":
        break;
      case "Yesterday":
        startDate.setDate(today.getDate() - 1);
        endDate = new Date(startDate);
        break;
      case "Last 7 Days":
        startDate.setDate(today.getDate() - 7);
        break;
      case "Last 30 Days":
        startDate.setDate(today.getDate() - 30);
        break;
      case "Last 90 Days":
        startDate.setDate(today.getDate() - 90);
        break;
      default:
        return [formatDate(today), formatDate(today)];
    }

    return [formatDate(startDate), formatDate(endDate)];
  };

  const formatDate = (date) => {
    if (typeof date === "string") {
      date = new Date(date);
    }
    if (!(date instanceof Date) || isNaN(date)) {
      console.error("Invalid date passed to formatDate", date);
      return "";
    }
    return date.toISOString().split("T")[0];
  };

  const renderTable = () => (
    <div className="table-container">
      <table className="revenue-table">
        <thead>
          <tr>
            <th>Transaction ID</th>
            <th>Date</th>
            <th>Product</th>
            <th>Type</th>
            <th>Customer name</th>
            <th>Coupon code</th>
            <th>Paid by customer</th>
            <th>Payment status</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => {
            const isWorkshop = item?.workshop_purchase !== null;
            const isSession = item?.session_purchase !== null;

            return (
              <tr key={index}>
                <td>{item?.transaction_id}</td>
                <td>{item?.date_created}</td>
                <td>
                  {isWorkshop
                    ? item?.workshop_purchase?.workshop?.title
                    : isSession
                      ? item?.session_purchase?.package?.title
                      : "N/A"}
                </td>
                <td>
                  {isWorkshop ? "Workshop" : isSession ? "Session" : "N/A"}
                </td>
                <td>{`${item?.user?.first_name} ${item?.user?.last_name}`}</td>
                <td>{item?.workshop_purchase?.coupon_code?.code || "N/A"}</td>
                <td>₹{item?.workshop_purchase?.total_pay}</td>
                <td>
                  <span
                    className={`status-tag ${item?.payment_status_str.toLowerCase()}`}
                  >
                    {item?.payment_status_str}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );

  return (
    <div className="revenue-dashboard">
      {loading && <LoadingOverlay />} {/* Show the loading spinner */}
      <h1 className="dashboard-title">Revenue</h1>
      <div className="tab-container">
        {["Overview", "Transactions"].map((tab) => (
          <button
            key={tab}
            className={`tab ${activeTab === tab ? "active" : ""}`}
            onClick={() => {
              setActiveTab(tab);
              navigate(`/profile/revenue/${tab.toLowerCase()}`);
            }}
          >
            {tab}
          </button>
        ))}
      </div>
      {activeTab === "Overview" ? (
        <Overview />
      ) : (
        <Transactions
          searchType={searchType}
          searchQuery={searchQuery}
          dateRange={dateRange}
          customDateRange={customDateRange}
          setCustomDateRange={handleCustomDateRangeChange}
          currentPage={currentPage}
          data={data}
          totalCount={totalCount}
          maxPages={maxPages}
          handleSearchQueryChange={handleSearchQueryChange}
          handleSearchTypeChange={handleSearchTypeChange}
          handleDateRangeChange={handleDateRangeChange}
          handlePreviousPage={handlePreviousPage}
          handleNextPage={handleNextPage}
          startDate={startDate}
          endDate={endDate}
          renderTable={renderTable}
        />
      )}
    </div>
  );
};

export default RevenueDashboard;
