import React from "react";
import styles from "./index.module.css";
import cardImg from "../../Assets/expert_page/wp.png";
import { Button } from "@mui/material";
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import ShareIcon from '@mui/icons-material/Share';
import forword from '../../Assets/forword.png'
import dummy from '../../Assets/dummy.png'
import { useNavigate } from "react-router-dom";
import Share from "../Share/Share";
function BlogsCard({data}) {
  // console.log(data,"BlogsCard")
  const navigate = useNavigate();

  const handleBlogNav = (id) => {
    navigate(`/blogs/${id}`)
  }
  return (<>  
   
    <div className={styles.cardContainer}>
        
      <div className={styles.imgContainer}>
        <img src={data?.header_image_url?.url} alt="" />
        <span>TRENDING</span>
      </div>
      <div className={styles.blogdetails}>
        <h5 className={styles.date}>{data?.published_date}</h5>
        <h4 className={styles.blogName}>
          {data?.title}
        </h4>
        <p className={styles.desc}>
          {data?.intro}
          {/* Babies and toddlers may throw tantrums for various reasons, and it's
          important to remember that tantrums are a normal part of child
          development. Here are some common reasons... */}
        </p>
        <div className={styles.btnContainer}>
            <Button variant="text" onClick={() => handleBlogNav(data?.id)} className={styles.readMore} endIcon={<KeyboardDoubleArrowRightIcon  className={styles.readMoreIcon} />}> Read More</Button>
            {/* <Button variant="text" className={styles.shareIcon} > <img src={forword} /> </Button> */}
            <Share />

        </div>
      </div>
    </div>
    </>
 );
}

export default BlogsCard;
 