import React, { useEffect } from "react";
import styles from "./index.module.css";
import { Link } from "react-router-dom";

function TermsAndCondition() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className={styles.mainContainer}>
        <h1> TERMS OF SERVICE</h1>
        <p>
          The Myburgo Website (“Website” available at Myburgo.com), Myburgo
          Mobile Applications (“Applications”) and Myburgo content, such as
          text, graphics, videos, images, information, and other material
          (“Content”) are provided to you by Myburgo, Inc. (“Myburgo”). Please
          take a moment to read these Terms of Service (“Agreement”), which
          represent a binding Agreement between you and Myburgo. By using our
          Website, Applications or Content you agree to be bound by this
          Agreement in its entirety, including the ARBITRATION AND CLASS ACTION
          WAIVER CLAUSES IN SECTION 15. This Agreement may be updated by Myburgo
          from time to time. If Myburgo makes material changes, we will provide
          notice to you either by email or (i) by posting the updated Terms of
          Service on our Website at least 15 days in advance of the effective
          date and (ii) posting a notification on our Applications that the
          Terms of Service have been updated. Please note that unless otherwise
          provided by applicable law, your continued use of our Website,
          Applications or Content means that you agree with, and consent to be
          bound by, the updated Terms of Service. The
          <Link to={"/privacy-policy"}> Myburgo’ Privacy Policy</Link> explains
          how we treat your personal data. By using the Website, Applications or
          Content you agree that Myburgo can use such data in accordance with
          our Privacy Policy.
        </p>
        <p>Our Agreement addresses the following topics:</p>

        <h4>1. Myburgo Does Not Provide Medical Advice </h4>
        <p>
          {" "}
          Our Website, Applications and Content are for informational purposes
          only. Myburgo does not provide any medical advice through its Website,
          Applications or Content. Any information provided through our Website,
          Applications or Content is not intended to be a substitute for
          professional medical advice, diagnosis, or treatment.
        </p>
        <p>
          Any information regarding your child’s progression, development or
          ability to perform the exercises or activities provided by our
          Website, Applications or Content is meant to be suggestive in nature
          as it is impossible to determine every child’s proper developmental
          progression as each child develops in a different manner and pace. You
          are solely responsible for determining your child’s developmental
          process and whether it is appropriate and safe to conduct any
          suggested activities and exercise with your child. If you have any
          suspicion of a developmental delay or any other health issue with your
          child, you should consult a physician or appropriate professional
          immediately. You are solely responsible for determining whether your
          child is physically or mentally able to perform any of the exercises
          or activities suggested in our Website, Applications or Content. We
          strongly urge you to consult a doctor or appropriate healthcare
          provider before using any information obtained in our Website,
          Applications or Content, especially if your child has a special
          medical condition.
        </p>
        <p>
          You are solely responsible for determining if your child requires
          treatment from a certified health professional. If at any time during
          the performance of any activity or exercise suggested in our Website,
          Applications or Content your child experiences any fatigue, increased
          or irregular heartbeat, pain, distress, crying, uncomfortableness,
          unwillingness or hesitation to perform, vomiting, vertigo, fainting,
          rash, limited range of motion, tenderness, swelling, pain, unusual
          sweating, or any other negative or detrimental response, stop
          immediately and consult a doctor. Do not resume performance of any of
          our suggested activities or exercises until obtaining prior approval
          from your child’s doctor. Never disregard professional medical advice
          or delay in seeking it for any reason. The exercises, activities,
          methods, products, procedures and opinions in our Website,
          Applications or Content are solely meant to be used for informational
          purposes only and are not meant to provide any guaranteed results.
        </p>

        <h4>2. Using Good Judgment </h4>
        <p>
          You are solely responsible for making sure that your child is safe.
          Never perform any activity or exercise that you believe your child
          might not be developmentally ready to do. If you have any doubt about
          your child’s developmental ability to perform any activity or
          exercise, ask a healthcare professional before performing it.
        </p>

        <p>
          Myburgo emphasizes the use of child-safe materials and toys around
          your child. You are solely responsible for making sure that any
          materials or toys used during our suggested activities or exercises
          are safe for your child. Avoid using materials or toys that may
          present a choking hazard and always monitor your child closely when
          using toys as many items can ultimately present a choking hazard.
          Activities or exercises with your child should always be performed by
          a competent adult in a safe environment to prevent injuries. Care
          should be exercised to reduce the risk of injury if the child falls
          down or doesn’t perform the activity or exercise in the expected or
          intended fashion. Activities or exercises suggested for the car must
          be performed by a competent adult who is not driving while the vehicle
          (i) is being driven at a safe speed and in a safe manner or (ii) is
          stopped. Myburgo cannot possibly foresee any and every possible risk
          to your child from the performance or participation in its suggested
          activities or exercises, and your use of good judgment and common
          sense is by far the best possible deterrent of injury to your child.
        </p>
        <p>
          Use common sense when you use our Website, Applications or Content or
          any related third party website, content or applications. The Website,
          Applications and Content are for informational purposes only. Despite
          our efforts to provide useful and accurate information, errors may
          appear from time to time. Some of the material you find on our
          Website, Applications or Content was not created, edited, or posted by
          us (for example, discussions or group postings). The materials posted
          by Users or other third parties on our Website, Applications or
          Content represent the views of such Users or other third parties and
          are not endorsed by us, nor do we guarantee the accuracy of any such
          material. Though we hope the advertisers, merchants and Users you meet
          through our Website, Applications or Content treat you honorably and
          fairly, we can’t vouch for the information, goods, or services they
          offer. Before you act on information you’ve found on or through any of
          our Website, Applications or Content, confirm any facts that are
          important to your decision. We encourage you to exercise a healthy
          skepticism and good judgment.
        </p>

        <h4>3. Registration</h4>
        <p>
          To obtain access to certain Myburgo services or content on our
          Website, Applications or Content, you may be given an opportunity to
          register and obtain an account with Myburgo. Your Myburgo account
          gives you access to content and functionality that we may establish,
          maintain, change, remove, or terminate from time to time and in our
          sole discretion. By connecting to our Website, Applications or Content
          with a third-party service, you give us permission to access and use
          your information from that service as permitted by that service, and
          to store your log-in credentials for that service. You may never use
          another User’s account without permission. When creating your account,
          you must provide accurate and complete information. You are solely
          responsible for the activity that occurs on your account, and you must
          keep your account password secure. You are responsible for taking all
          reasonable steps to ensure that no unauthorized person shall have
          access to your Myburgo passwords or accounts. It is your sole
          responsibility to: (1) control the dissemination and use of sign-in
          name, screen name and passwords; (2) authorize, monitor, and control
          access to and use of your Myburgo account and password; (3) promptly
          inform us if you believe your account or password has been compromised
          or if there is any other reason you need to deactivate a password. You
          will notify us immediately via e-mail of any known or suspected
          unauthorized use of your account by using the “Contact Us” link
          located on our Website or Applications.
        </p>
        <h4>4. User Submissions and Ideas</h4>

        <p>
          Responsibility for what is posted in the discussion groups or other
          public forums on our Website, Applications or Content lies with each
          User. You alone are responsible for the content of your messages, and
          the consequences of any such messages. You agree not to use our
          Website, Applications or Content to send or submit materials that: (a)
          are false, inaccurate or misleading; (b) infringe any third party’s
          copyright, patent, trademark, trade secret or other proprietary rights
          or rights of publicity or privacy; (c) violate any law, statute,
          ordinance or regulation (including without limitation those governing
          export control, consumer protection, unfair competition,
          anti-discrimination and false advertising); (d) are libelous,
          threatening or harassing; (e) are obscene or contain any kind of
          pornography; (f) contain any viruses, Trojan horses, spyware, worms,
          time bombs, cancelbots or other computer programming routines that are
          intended to damage, detrimentally interfere with, surreptitiously
          intercept or expropriate any system, data or personal information; (g)
          might create liability for Myburgo or might cause us to lose (in whole
          or in part) the services of our Internet service providers or other
          suppliers; (h) interfere with the ability of others to enjoy our
          Website, Applications or Content; (i) impersonate any other person or
          entity, whether actual or fictitious, including impersonating an
          employee or consultant of Myburgo; (j) link to or include descriptions
          of goods or services that: (i) are prohibited under this Agreement; or
          (ii) you do not have a right to link to or include; (k) contain any
          content that you do not have the right to make available under any law
          or otherwise, including any contractual or fiduciary relationship
          (such as inside information or confidential information learned under
          a non-disclosure agreement); or (l) instigate or encourage others to
          commit illegal activities or cause injury or property damage to any
          person. We neither endorse nor guarantee the accuracy or propriety of
          any submission by a User of other third-party. We do, however, reserve
          the right, but do not assume the obligation, to restrict or prohibit
          your use of our Website, Applications or Content if we believe you are
          violating any of the terms of this Agreement and to remove, edit, or
          relocate any submission as we see fit, whether for legal or other
          reasons.
        </p>

        <p>
          You may choose to or we may invite you to submit comments or ideas
          about our Website, Applications or Content about how to improve them
          (“Ideas”). By submitting any Idea, you agree that your disclosure is
          gratuitous, unsolicited and without restriction and will not place
          Myburgo under any obligation whatsoever, and that we are free to use
          the Idea without any additional compensation to you, and/or to
          disclose the Idea to anyone. You further acknowledge that, by
          acceptance of your submission, Myburgo does not waive any rights to
          use similar or related ideas previously known to us, or developed by
          our employees or Affiliates, or obtained from sources other than you.
        </p>

        <h4>6. Use of Our Website, Applications and Content</h4>

        <p>
          Your use of the Website, Applications or Content is limited to
          personal, non-commercial purposes. Your commercial exploitation of the
          Website, Applications or Content is strictly prohibited, unless we
          grant prior written permission.
        </p>
        <p>
          Our Website, Applications and Content are protected by copyright under
          both United States and foreign laws. Title to and interest in our
          Website, Applications and Content remain with Myburgo or its
          licensors. Any use of our Website, Applications or Content not
          expressly permitted herein is a breach of this Agreement and may
          violate copyright, trademark, and other laws. You may use our Website,
          Applications or Content only if you are legally competent to enter
          into a binding contract with Myburgo, and only in compliance with this
          Agreement and all applicable local, state, national, and international
          laws, rules and regulations. Our Website, Applications and Content are
          not available to anyone previously removed as a User of our Web Site.
        </p>
        <p>
          You agree not to engage in any of the following prohibited activities:
          copying, distributing, or disclosing (except as provided by the
          Myburgo limited license in Section 8) the Myburgo Website,
          Applications or Content in any medium, including without limitation by
          any automated or non-automated “scraping”; using any automated system,
          including without limitation “robots,” “spiders,” “offline readers,”
          etc., to access our Website, Applications or Content in a manner that
          sends more request messages to the servers we use than a human can
          reasonably produce in the same period of time by using a conventional
          on-line web browser (except that Myburgo grants the operators of
          public search engines revocable permission to use spiders to copy
          information from Myburgo.com for the sole purpose of creating publicly
          available searchable indices of the materials); transmitting spam,
          chain letters, or other unsolicited email; attempting to interfere
          with, compromise our system integrity or security or decipher any
          transmissions to or from the servers we use; taking any action that
          imposes, or may impose at our sole discretion an unreasonable or
          disproportionately large load on our infrastructure; uploading invalid
          data, viruses, spyware, worms, or other software agents; collecting or
          harvesting any personally identifiable information, including account
          names, from the servers we use; using our Website, Applications or
          Content for any commercial or solicitation purposes; impersonating
          another person or otherwise misrepresenting your affiliation with a
          person or entity, conducting fraud, hiding or attempting to hide your
          identity; interfering with the proper working of our Website,
          Applications or Content; accessing our Website, Applications or
          Content through any technology or means other than those provided or
          authorized by Myburgo; bypassing the measures we may use to prevent or
          restrict access to our Website, Applications or Content, including
          without limitation features that prevent or restrict use or copying of
          any content or enforce limitations on use of our Website, Applications
          or Content.
        </p>

        <p>
          Our Website, Applications or Content are subject to change or
          termination without notice at our sole discretion. All rights not
          expressly granted herein are reserved to Myburgo and its licensors. If
          you violate any of the terms and conditions of this Agreement, your
          permission to use our Website, Applications and Content automatically
          terminates.
        </p>
        <p>
          Upon termination for any reason or no reason, you continue to be bound
          by this Agreement. You are solely responsible for your interactions
          with other Myburgo Users. We reserve the right, but have no
          obligation, to monitor interaction between you and other Users.
          Myburgo shall have no liability for your interactions with other
          Users, or for any User’s action or inaction.
        </p>
        <p>
          You are free to encourage others to access the information on our
          Website, Applications and Content. We welcome links to our Website,
          Applications and Content. You are free to establish a hypertext link
          to any of our Website, Applications and Content so long as the link
          does not state or imply any sponsorship or endorsement of your site by
          us or make use of a logo without written consent of the logo owner.
        </p>
        <p>
          Without our prior written consent, you may not frame, or in-line link
          to, any of the content of our Website, Content or Applications, or
          incorporate into any other website or service any of our intellectual
          property.
        </p>

        <h4>7. Myburgo’ Limited License to You</h4>
        <p>
          Subject to the terms and conditions of this Agreement, you are hereby
          granted a non-exclusive, limited, non-transferable, revocable license
          to use the Myburgo Website, Applications and Content for personal,
          non-commercial purposes and to display and print for your personal,
          non-commercial use information you receive through our Website,
          Applications or Content. You may not reproduce, distribute, or
          otherwise use any of the Website, Applications or Content or any
          derivative work without the prior written consent of the Intellectual
          Property Rights holder. Requests for permission to reproduce,
          distribute or otherwise use materials found on our Website,
          Applications or Content should be made in writing to us via email by
          using the “Contact Us” link located on our Website or Applications.
          The burden of determining whether any of the Website, Applications or
          Content is or is not protected by Intellectual Property Rights rests
          with you. Myburgo may terminate this license at any time for any
          reason or no reason.
        </p>

        <p>
          This Agreement, and any rights and licenses granted under this
          Agreement, may not be transferred or assigned by you, but may be
          assigned by Myburgo without restriction. Any attempted transfer or
          assignment by you in violation hereof shall be null and void.
        </p>

        <h4>8. Your License to Myburgo</h4>

        <p>
          By submitting material to or through our Website, Applications and
          Content, you grant us and our partners, agents, affiliates and service
          providers (collectively, “Affiliates”), a non-exclusive, transferable,
          non-revocable, worldwide license to reproduce, modify and distribute
          such material as we see fit for any purpose in any form, media, or
          technology now known or later developed. You agree that we and our
          Affiliates may identify you as the author of any of your postings by
          name, email address or screen name as we see appropriate. You also
          permit any other User to access, display, and print such material for
          personal use. By submitting material to us, you are representing that
          such material does not violate or infringe any rights of any third
          party, including, but not limited to, Intellectual Property Rights. If
          any third-party content is included in any materials that you submit,
          you must obtain permission from the content owner and attribute such
          content to the owner.
        </p>

        <h4>9. Indemnity</h4>
        <p>
          You agree to defend, indemnify and hold harmless Myburgo, its
          officers, directors, managers, employees, agents, servants,
          contractors, Affiliates, licensors, parents, subsidiaries,
          shareholders, owners, members, and any other affiliated companies,
          entities or persons, from and against any and all claims, actions,
          suits, demands, damages, obligations, losses, liabilities, costs or
          debt, and expenses (including but not limited to attorney’s fees)
          (collectively “Claims”) arising out of or relating to: your use of and
          access to our Website, Applications or Content, including any data,
          content or material provided or received by you; your violation of any
          term of this Agreement, including without limitation your breach of
          any representations and warranties herein; your violation of any
          third-party right, including without limitation any right of privacy
          or Intellectual Property Rights; your violation of any applicable law,
          rule or regulation; any Claim that arises as a result of any of your
          User information or any that is submitted via your account; any other
          party’s access and use of our Website, Applications or Content with
          your unique username, password or other appropriate security code.
        </p>

        <h4>10. Third-Party Services</h4>

        <p>
          We may provide links to third-party websites, applications or content.
          Those third-party websites, applications or content and the parties
          that control them are beyond our control. We make no representations
          as to the content, quality, suitability, functionality or legality of
          any third-party websites, applications or content to which we may
          provide links, and you hereby waive any Claim you might have against
          Myburgo, its officers, directors, managers, employees, agents,
          servants, contractors, Affiliates, licensors, parents, subsidiaries,
          shareholders, owners, members, and any other affiliated companies,
          entities or persons (other than the providers of the third-party
          websites, applications or content) with respect to such websites,
          applications or content. You may order goods or services through links
          on our Website, Applications or Content from third parties. All
          matters concerning such goods or services, including, but not limited
          to, purchase terms, payment terms, warranties, guarantees, maintenance
          and delivery, are solely between you and the third parties with whom
          you chose to do business. We make no warranties or representations
          whatsoever with regard to any goods or services provided by those
          third parties. You will not consider us – nor will we be deemed – a
          party to such transactions, whether or not we may have received some
          form of remuneration in connection with the transaction. We will not
          be liable for any costs or damages arising out of or relating to any
          transaction (whether directly or indirectly) between you and any other
          person or entity.
        </p>

        <p>
          MYBURGO DOES NOT WARRANT, ENDORSE, RECOMMEND, GUARANTEE, OR ASSUME
          RESPONSIBILITY FOR ANY GOOD OR SERVICE ADVERTISED OR OFFERED BY A
          THIRD PARTY THROUGH OUR WEBSITE, APPLICATIONS OR CONTENT, OR ANY
          HYPERLINKED THIRD-PARTY WEBSITE, APPLICATION, GOOD OR SERVICE. MYBURGO
          RESERVES THE RIGHT, BUT HAS NO OBLIGATION, TO MONITOR ANY TRANSACTION
          AND/OR COMMUNICATION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF GOODS OR
          SERVICES.
        </p>

        <h4>11. Disclaimer of Warranties</h4>
        <p>
          THE MYBURGO WEBSITE, APPLICATIONS, CONTENT AND ANY INFORMATION,
          MATERIALS, GOODS AND SERVICES PROVIDED BY OUR AFFILIATES, LICENSORS,
          USERS, OR OTHER THIRD-PARTIES ARE PROVIDED ON AN “AS IS” AND “AS
          AVAILABLE” BASIS. USE OF MYBURGO WEBSITE, APPLICATIONS, CONTENT AND
          ANY INFORMATION, MATERIALS, GOODS AND SERVICES PROVIDED BY OUR
          AFFILIATES, LICENSORS, USERS, OR OTHER THIRD-PARTIES IS AT YOUR OWN
          RISK.
        </p>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE MYBURGO
          WEBSITE, APPLICATIONS AND CONTENT AND ANY INFORMATION, MATERIALS,
          GOODS AND SERVICES PROVIDED BY OUR AFFILIATES, LICENSORS, USERS, OR
          OTHER THIRD-PARTIES ARE PROVIDED WITHOUT WARRANTIES OF ANY KIND BY
          MYBURGO, WHETHER EXPRESSED OR IMPLIED. Myburgo EXPRESSLY DISCLAIMS ANY
          EXPRESS OR IMPLIED WARRANTIES OF: (1) ACCURACY, (2) COMPLETENESS, (3)
          CURRENTNESS, (4) RELIABILITY, (5) MERCHANTABILITY, (6) FITNESS FOR A
          PARTICULAR PURPOSE, (7) NON-INFRINGEMENT, OR (8) TITLE.
        </p>
        <p>
          MYBURGO FURTHER EXPRESSLY DISCLAIMS THAT THE WEBSITE, APPLICATIONS OR
          CONTENT WILL MEET YOUR REQUIREMENTS OR WILL BE AVAILABLE AT ANY
          PARTICULAR TIME OR LOCATION, OR WILL BE UNITERRUPTED OR SECURE, OR
          THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED.
        </p>

        <p>
          NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU
          FROM Myburgo, INCLUDING ON OUR WEBSITE, APPLICATIONS OR CONTENT, WILL
          CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN.
        </p>

        <h4>12. Limitation of Liability</h4>
        <p>
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
          MYBURGO, ITS OFFICERS, DIRECTORS, MANAGERS, EMPLOYEES, AGENTS,
          SERVANTS, CONTRACTORS, AFFILIATES, LICENSORS, PARENTS, SUBSIDIARIES,
          SHAREHOLDERS, OWNERS, MEMBERS OR ANY OTHER AFFILIATED COMPANIES,
          ENTITIES OR PERSONS BE LIABLE UNDER ANY THEORY OF LIABILITY, INCLUDING
          UNDER CONTRACT, TORT, NEGLIGENCE OR OTHERWISE FOR ANY INDIRECT,
          PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR SIMILAR
          DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS,
          GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, THAT RESULT FROM THE
          USE OF, OR INABILITY TO USE, OUR WEBSITE, APPLICATIONS, CONTENT, OR
          ANY INFORMATION, MATERIALS, GOODS AND SERVICES PROVIDED BY OUR
          AFFILIATES, LICENSORS, USERS OR OTHER THIRD PARTIES, EVEN IF WE ARE
          ADVISED BEFORE HAND OF THE POSSIBILITY OF SUCH DAMAGES. UNDER NO
          CIRCUMSTANCES WILL MYBURGO BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR
          INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS
          OR USE OF OUR WEBSITE, APPLICATIONS OR CONTENT OR YOUR ACCOUNT OR THE
          INFORMATION CONTAINED THEREIN.
        </p>

        <p>
          WITH RESPECT TO OUR WEBSITE, APPLICATIONS, CONTENT, AND ANY
          INFORMATION, MATERIALS, GOODS AND SERVICES PROVIDED BY OUR AFFILIATES,
          LICENSORS, USERS OR OTHER THIRD PARTIES AND TO THE MAXIMUM EXTENT
          PERMITTED BY APPLICABLE LAW, Myburgo, ITS OFFICERS, DIRECTORS,
          MANAGERS, EMPLOYEES, AGENTS, SERVANTS, CONTRACTORS, AFFILIATES,
          LICENSORS, PARENTS, SUBSIDIARIES, SHAREHOLDERS, OWNERS, MEMBERS OR ANY
          OTHER AFFILIATED COMPANIES, ENTITIES OR PERSONS SHALL NOT BE LIABLE
          FOR ANY (I) ERRORS, MISTAKES, OMISSIONS OR INACCURACIES ; (II)
          PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
          RESULTING FROM YOUR ACCESS TO OR USE OF OUR WEBSITE, APPLICATIONS,
          CONTENT OR ANY INFORMATION, MATERIALS, GOODS AND SERVICES PROVIDED BY
          OUR AFFILIATES, LICENSORS, USERS OR OTHER THIRD PARTIES; (III)
          UNAUTHORIZED ACCESS TO OR USE OF SERVERS WE USE AND/OR ANY AND ALL
          PERSONAL INFORMATION STORED THEREIN; (IV) INTERRUPTION OR CESSATION OF
          TRANSMISSION TO OR FROM THE SERVERS WE USE; (V) BUGS, VIRUSES, TROJAN
          HORSES, SPYWARE OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE
          SERVERS WE USE BY ANY THIRD PARTY; (VI) LOSS OR DAMAGE INCURRED AS A
          RESULT OF THE USE OF OUR WEBSITE, APPLICATIONS, CONTENT OR ANY
          INFORMATION, MATERIALS, GOODS AND SERVICES PROVIDED BY OUR AFFILIATES,
          LICENSORS, USERS OR OTHER THIRD PARTIES AND/OR (VII) USER CONTENT OR
          THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY.
        </p>
        <p>
          IN NO EVENT SHALL MYBURGO, ITS OFFICERS, DIRECTORS, MANAGERS,
          EMPLOYEES, AGENTS, SERVANTS, CONTRACTORS, AFFILIATES, LICENSORS,
          PARENTS, SUBSIDIARIES, SHAREHOLDERS, OWNERS, MEMBERS OR ANY OTHER
          AFFILIATED COMPANIES, ENTITIES OR PERSONS BE LIABLE TO YOU FOR ANY
          CLAIMS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO MYBURGO HEREUNDER
          OR $100.00, WHICHEVER IS GREATER. THIS LIMITATION OF LIABILITY SECTION
          APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT,
          NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF WE HAVE BEEN
          ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF
          LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE
          APPLICABLE JURISDICTION.
        </p>
        <p>
          SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES OR
          THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
          THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THIS
          AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER
          RIGHTS, WHICH VARY FROM JURISDICTION TO JURISDICTION. THE DISCLAIMERS,
          EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THIS AGREEMENT WILL NOT
          APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
        </p>

        <h4>14. Applicable Law</h4>
        <p>
          Users who choose to access our Website, Applications or Content from
          states within the United State of America do so on their own
          initiative and are responsible for compliance with applicable local,
          state and federal laws. Users who access or use our Website,
          Applications or Content from jurisdictions outside of the United
          States do so at their own volition and are entirely responsible for
          compliance with all applicable United States, foreign and local laws
          and regulations, including but not limited to export and import
          regulations. You may not use our Website, Applications or Content if
          you are a resident of a country embargoed by the United States, or are
          a foreign person or entity blocked or denied by the United States
          government.
        </p>

        <p>
          This Agreement and any Claims arising out of or relating to this
          Agreement, our Website, Applications or Content shall be governed by
          the laws of the State of Florida, without regard to its conflict of
          laws principles.
        </p>

        <h4>
          15. Dispute Resolution, Jurisdiction, Arbitration and Class Action
          Waiver
        </h4>

        <p>
          For any dispute or Claim against Myburgo, you agree to first email us
          and attempt to resolve the dispute or Claim with us informally. To
          send us an email, use the “Contact Us” link located on our Website or
          Applications. In the unlikely event that we cannot resolve a dispute
          or Claim after attempting to do so informally, we each agree to
          resolve any dispute or Claim (excluding any Claims brought by Myburgo
          for injunctive or other equitable relief as set forth below) arising
          out of or relating to this Agreement, the breach or alleged breach
          thereof, the Website, Applications or Content, either in small claims
          court in the United States (and specifically in Miami-Dade county,
          Florida, as agreed to below) or by binding arbitration with the
          American Arbitration Association (“AAA”) under the Commercial
          Arbitration Rules and Supplementary Procedures for Consumer Related
          Disputes then in effect for the AAA, except as provided herein. The
          arbitration will be conducted in Miami-Dade County, Florida, unless
          you and Myburgo agree otherwise. Each party will be responsible for
          paying any AAA filing, administrative and arbitrator fees in
          accordance with AAA rules. The award rendered by the arbitrator shall
          award to the prevailing party, if any, as determined by the arbitrator
          its costs of arbitration, reasonable attorneys’ fees and reasonable
          costs for expert and other witnesses, and any judgment on the award
          rendered by the arbitrator may be entered in any court of competent
          jurisdiction. For any small claims matters, we each agree such Claims
          shall be brought exclusively in the small claims courts in Miami-Dade
          County, Florida (to the extent permissible under the Miami-Dade County
          small claims courts) and the parties consent to personal jurisdiction
          in the Miami-Dade County small claims courts.
        </p>
        <p>
          Nothing in this Section shall prevent Myburgo from seeking injunctive
          or other equitable relief from a court of competent jurisdiction,
          including to prevent the actual or threatened infringement,
          misappropriation, or violation of data security, Intellectual Property
          Rights, or other proprietary rights. You agree to submit exclusively
          to the personal jurisdiction of the federal and state courts located
          in Miami-Dade County, Florida for any such action.
        </p>
        <p>
          ALL CLAIMS MUST BE BROUGHT IN YOUR INDIVIDUAL CAPACITY, AND NOT AS A
          PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
          PROCEEDING, AND, UNLESS WE EACH AGREE OTHERWISE, THE ARBITRATOR MAY
          NOT CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY
          ENTERING INTO THIS AGREEMENT, YOU ARE WAIVING THE RIGHT TO A TRIAL BY
          JURY OR TO PARTICIPATE IN A CLASS ACTION.
        </p>

        <h4>16. Notifications</h4>
        <p>
          Myburgo may provide notifications, whether such notifications are
          required by law or are for marketing or other business related
          purposes, to you via email notice, written or hard copy notice, or
          through posting of such notice on our Website, Applications or
          Content, as we determine in our sole discretion. Myburgo reserves the
          right to determine the form and means of providing notifications to
          our Users, provided that you may opt out of certain means of
          notification as described below. Myburgo is not responsible for any
          automatic filtering you or your network provider may apply to email
          notifications we send to the email address you provide us.
        </p>
        <p>
          By providing Myburgo your email address you consent to our using the
          email address to send you service-related notices, including any
          notices required by law, in lieu of communication by postal mail. We
          may also use your email address to send you other messages, such as
          changes to features of our Website, Applications and Content and
          special offers. If you do not want to receive such email messages, you
          may opt out by following the “unsubscribe” link at the bottom of our
          email. Opting out may prevent you from receiving email messages
          regarding updates, improvements, or offers.
        </p>

        <h4>17. Miscellaneous</h4>
        <p>
          In the event that any provision (or part thereof) of this Agreement
          conflicts with the law under which this Agreement is to be construed
          or if any provision (or part thereof) is held invalid by a court or
          arbitrator with jurisdiction over the parties to the Agreement, such
          provision (or part thereof) will be restated to reflect as nearly as
          possible the original intentions of the parties in accordance with
          applicable law, and the remainder of this Agreement will remain in
          full force and effect. Section headings are for convenience only and
          shall not be part of the terms and condition of this Agreement.
        </p>
        <p>
          If either party fails to insist upon or enforce strict performance by
          the other party of any provision of the Agreement, or to exercise any
          right under the Agreement, such a failure will not be construed as a
          waiver or relinquishment to any extent of such party’s right to assert
          or rely upon any such provision or right in that or any other
          instance. No waiver of any term of this Agreement shall be deemed a
          further or continuing waiver of such term or any other term, and
          Myburgo’ failure to assert any right or provision under this Agreement
          shall not constitute a waiver of such right or provision. This
          Agreement constitutes the entire understanding between the parties as
          to the subject matter hereof, and supersedes all prior agreements and
          understandings relating to such subject matter.
        </p>
      </div>
    </>
  );
}

export default TermsAndCondition;
