// src/components/Dialog/PaymentDialog.jsx
import React, { useState, useEffect, useRef } from "react";
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import {
  DialogContainer,
  LeftSection,
  RightSection,
  Card,
  Button,
  OtpContainer,
  ServiceCard,
  AmountCard,
  OtpInputContainer,
  ResendOtpLink,
  SuccessMessage,
  Label,
  InputField,
  PayButton,
  ErrorText,
  CouponContainer,
  CouponInput,
  ApplyButton,
  Select,
} from "./PaymentDialogStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import {
  open_api,
  open_api_without_auth,
  open_api_with_user_token,
} from "../../Utils/network";
import ConfirmationDialog from "./ConfirmationDialog";
import LoadingOverlay from "./LoadingOverlay";
import checkIcon from "../../Assets/dialog/checkIcon.svg";
import failureIcon from "../../Assets/dialog/failureIcon.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import CongratulationsBanner from "../SuccessCard/CongratulationsBanner";
import CustomAlert from "../AlertDialog/CustomAlert";
import { useNavigate, useLocation } from "react-router-dom";
import GoogleSignIn from "../Login/GoogleSignIn";
import successIcon from "../../Assets/feedback/successIcon.svg";
import FailedIcon from "../../Assets/feedback/failedIcon.svg";
import {
  fetchCountryCode,
  validatePhoneNumber,
  validateEmail,
  handleOTPChange,
  handleKeyDown,
  handlePhoneNumberChange,
  handleEmailChange,
  applyCoupon,
  requestOTP,
  validateOTP,
  addAlert,
  removeAlert,
  fetchProfileTypes,
  handleCouponChange,
  checkUserInfoExists,
  fetchUserDetails,
  postExpertUserPurchase,
} from "./helper";
import useAuth from "../Hook/useAuth";

const PaymentDialog = ({
  open,
  onClose,
  image,
  title,
  expertIds,
  description,
  sellingPrice,
  mrpPrice,
  workshop_id,
  date,
  time,
  isLive,
  whatsapplink,
}) => {
  const [useEmail, setUseEmail] = useState(false);
  const [contactValue, setContactValue] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [otpValidated, setOtpValidated] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isConfirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [isPaymentSuccessful, setPaymentSuccessful] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dialogProps, setDialogProps] = useState({});
  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [countryCode, setCountryCode] = useState("in");
  const inputRefs = useRef([]);
  const [coupon, setCoupon] = useState("");
  const [discountAmount, setDiscountAmount] = useState(0);
  const [couponFound, setCouponFound] = useState(false);
  const [bearerLocalToken, setBearerToken] = useState("");
  const [userLocalId, setUserId] = useState(0);
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [newSellingPrice, setNewSellingPrice] = useState(0);
  const [matchingCoupon, setMatchingCoupon] = useState({});
  const [resentOTP, setResentOTP] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [profileTypeId, setProfileTypeId] = useState(0);
  const [profileType, setProfileType] = useState("");
  const [profileTypeError, setProfileTypeError] = useState(false);
  const [profileTypes, setProfileTypes] = useState([]);
  const [userLocalName, setUserName] = useState("");
  const { isAuthenticated, userName, userId, bearerToken } = useAuth();
  const [isUserInfoExists, setIsUserInfoExists] = useState(false);
  const [expertDetailsList, setExpertDetailsList] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {}, [isAuthenticated]);
  useEffect(() => {
    const updateUserDetails = async () => {
      if (isAuthenticated && bearerToken && userId) {
        // Ensure all necessary data is available
        setBearerToken(bearerToken);
        setUserName(userName);
        setUserId(userId);
        const userInfo = await fetchUserDetails(bearerToken, userId);
        setPhoneNumber(userInfo?.phone);
        setEmail(userInfo?.email);
        setfirstName(userInfo?.first_name);
        setLastName(userInfo?.last_name);
        setIsUserInfoExists(
          checkUserInfoExists(
            userInfo?.first_name,
            userInfo?.last_name,
            userInfo?.email
          )
        );
      }
    };
    updateUserDetails();
  }, [isAuthenticated, bearerToken, userId, userName]);

  useEffect(() => {
    const getCountryCode = async () => {
      const countryCode = await fetchCountryCode();
      setCountryCode(countryCode);
    };
    getCountryCode();
  }, [countryCode]);

  useEffect(() => {
    if (expertIds && expertIds.length > 0) {
      fetchExpertDetails(expertIds);
    }
  }, [expertIds]);

  const fetchExpertDetails = async (expertIds) => {
    try {
      const expertDetailsPromises = expertIds.map((id) =>
        open_api.get(`/profiles/expert/${id}`)
      );

      const expertsResponse = await Promise.all(expertDetailsPromises);
      const expertsData = await Promise.all(
        expertsResponse.map(async (res) => {
          return res.data;
        })
      );

      setExpertDetailsList(expertsData);
      //console.log("Fetched expert details with packages: ", expertsData);
    } catch (error) {
      console.error("Error fetching expert details:", error);
    }
  };

  const priceSymbol = countryCode === "in" ? "₹" : "$";
  const isUSD = countryCode === "in" ? "INR" : "USD";

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && resentOTP) {
      setResentOTP(false);
    }
  }, [countdown, resentOTP]);

  useEffect(() => {
    const fetchTypes = async () => {
      var accessToken = bearerLocalToken;
      if (accessToken === "") {
        accessToken = localStorage.getItem("bearerToken");
      }
      await fetchProfileTypes(accessToken, setProfileTypes);
    };
    fetchTypes();
  }, [bearerLocalToken]);

  const handleApplyCoupon = async () => {
    await applyCoupon(
      coupon,
      workshop_id,
      null,
      bearerLocalToken,
      sellingPrice,
      setShowAlert,
      handleAddAlert,
      setCouponFound,
      setMatchingCoupon,
      setNewSellingPrice,
      setDiscountAmount,
      setCoupon
    );
  };

  const applyCouponCode = async (workShopDetails) => {
    //console.log("couponcodeFound", couponFound);
    const localData = {
      workshop_id: workshop_id ?? 0,
      ...(couponFound && { coupon_code_id: matchingCoupon.id ?? 0 }),
    };
    try {
      const res = await open_api_with_user_token(bearerLocalToken).patch(
        `/workshop/session/card/${workShopDetails.id}/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        const userDetails = res?.data?.user_detail;
        setPhoneNumber(userDetails?.phone);
        return res?.data || [];
      }
    } catch (error) {
      //console.log("failed to get coupon list");
    }
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  const handleProfileTypeChange = (e) => {
    const selectedId = e.target.value;
    const selectedType = profileTypes.find(
      (type) => type.id.toString() === selectedId
    );
    if (selectedType) {
      setProfileType(selectedType.title);
      setProfileTypeId(selectedType.id);
    } else {
      setProfileType("");
    }
    setProfileTypeError(false);
  };

  const handleToggle = () => {
    setUseEmail(!useEmail);
    setContactValue("");
  };

  const handleRequestOTP = () => {
    setEmailError(false);
    setPhoneNumberError(false);
    if (
      useEmail
        ? validateEmail(email, setEmailError, setShowAlert, handleAddAlert)
        : validatePhoneNumber(
            phoneNumber,
            setPhoneNumberError,
            setShowAlert,
            handleAddAlert
          )
    ) {
      requestOTP(phoneNumber, setOtpRequested, setShowAlert, handleAddAlert);
      // Request OTP logic here
    } else {
    }
  };

  const handleSubmitOTP = async () => {
    await handleValidateOTP();
  };

  const handleResendOTP = () => {
    handleRequestOTP();
    setResentOTP(true);
    setCountdown(30);
  };

  const handlePhoneInputChange = (value, data) => {
    handlePhoneNumberChange(
      value,
      data,
      setCountryCode,
      setPhoneNumber,
      setPhoneNumberError
    );
  };

  const handleEmailInputChange = (e) => {
    handleEmailChange(e, setEmail, setEmailError);
  };

  const handlePhoneNumberInputChange = (e) => {
    if (e.target.name === "phoneNumber") {
      setPhoneNumberError(false);
    }
    setPhoneNumber(e.target.value);
  };

  const handlefirstNameChange = (e) => {
    if (e.target.name === "firstName") {
      setfirstNameError(false);
    }
    setfirstName(e.target.value);
  };

  const handlelastNameChange = (e) => {
    if (e.target.name === "lastName") {
      setLastNameError(false);
    }
    setLastName(e.target.value);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setUseEmail(false);
      setOtpRequested(false);
      setContactValue("");
      setPaymentSuccessful(false);
      setVerified(false);
      setPhoneNumberError(false);
      setEmailError(false);
      setfirstNameError(false);
      setLastNameError(false);
      onClose();
      setOtp(Array(4).fill(""));
      setCouponFound(false);
      setCoupon([]);
      setDiscountAmount(0);
      setOtpValidated(false);
      setCountryCode("in");
      setProfileType("");
      setProfileTypeError(false);
      setMessage("");
      if (!isAuthenticated) {
        setEmail("");
        setPhoneNumber("");
        setfirstName("");
        setLastName("");
        setBearerToken("");
        setUserId(0);
      }
      window.location.reload();
    }
  };

  const getWorkshopPayData = async () => {
    const workShopDetails = await getWorkshopCartDetails();
    const workshopPurchaseId = workShopDetails.id ?? 0;
    const appliedCoupon = await applyCouponCode(workShopDetails);
    const localData = {
      workshop_purchase_id: workshopPurchaseId,
      amount: appliedCoupon.total_amt,
    };
    try {
      const res = await open_api_with_user_token(bearerLocalToken).post(
        `/payment/pay/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        return { paymentData: res?.data, appliedCoupon, workshopPurchaseId };
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const getWorkshopCartDetails = async () => {
    const localData = {
      workshop_id: workshop_id ?? 0,
      currency: isUSD,
      purchasedFrom: 1,
    };

    try {
      const res = await open_api_with_user_token(bearerLocalToken).post(
        `/workshop/session/card/`,
        localData
      );
      //console.log(res);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        return res?.data;
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const handleValidateOTP = async () => {
    await validateOTP(
      otp,
      phoneNumber,
      setOtpValidated,
      setVerified,
      setUserId,
      setBearerToken,
      setShowAlert,
      handleAddAlert,
      setfirstName,
      setLastName,
      setEmail,
      setUserName
    );
  };

  const splitFullName = (fullName) => {
    const nameParts = fullName.split(" ");

    const firstName = nameParts.length > 0 ? nameParts[0] : "";
    const lastName =
      nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";

    const middleName =
      nameParts.length > 2
        ? nameParts.slice(1, nameParts.length - 1).join(" ")
        : "";

    return {
      first_name: firstName,
      middle_name: middleName,
      last_name: lastName,
    };
  };

  const sumbitUserDetails = async () => {
    let registerAsId, title;
    switch (profileType.toLowerCase()) {
      case "mom":
        title = "Mrs";
        break;
      case "dad":
        title = "Mr";
        break;
      case "grand pa":
        title = "Mr";
        break;
      case "grand ma":
        title = "Mrs";
        break;
      default:
        title = "Mr/Mrs";
    }

    const userDetails = {
      id: userLocalId,
      phone: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      email: email,
      register_as_id: profileTypeId,
      title: title,
    };
    try {
      const res = await open_api_with_user_token(bearerLocalToken).patch(
        `/profiles/update_profile/`,
        userDetails
      );

      //console.log(res);
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        localStorage.setItem("userName", firstName + " " + lastName);
        setUserName(firstName + " " + lastName);
        return true;
      } else if (res?.status === 400) {
        if (res?.data?.email != null) {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data?.email, "error", true);
          setIsLoading(false);
          return false;
        }
      }
      return false;
    } catch (err) {
      console.error("Error in sumbitUserDetails:", err);
      return false;
    }
  };

  const handleOTPInputChange = (e, index) => {
    handleOTPChange(e, index, otp, setOtp, inputRefs);
  };

  const handleOTPKeyDown = (e, index) => {
    handleKeyDown(e, index, otp, inputRefs);
  };

  const initiateRazorpayPayment = async (
    paymentData,
    appliedCoupon,
    workshopPurchaseId
  ) => {
    setMessage("Confirming your order, please don't close the screen...");
    const options = {
      //key: "rzp_test_41tt7Y8uKXr9rl",
      key: "rzp_live_0MAqWVbokRW0Uu",
      amount: paymentData.amount * 100, // Razorpay works with paise, so multiply by 100
      currency: "INR",
      name: "myburgo",
      description: "Payment for Workshop",
      order_id: paymentData.order_id,
      timeout: 300,
      handler: (response) => {
        handlePaymentSuccess(paymentData.id, workshopPurchaseId);
      },
      prefill: {
        name: `${firstName} ${lastName}`,
        email: appliedCoupon.user_detail.email,
        contact: appliedCoupon.user_detail.phone,
      },
      notes: {
        address: "website",
      },
      theme: {
        color: "#7A53FF",
      },
      modal: {
        ondismiss: function () {
          handlePaymentFailure(
            { error: "Payment window closed without completing the payment." },
            paymentData.id
          );
        },
      },
    };
    const rzp = new window.Razorpay(options);

    rzp.on("payment.failed", ({ response }) => {
      console.error("Payment failed:", response.error);
    });

    rzp.open();
  };

  const fetchWorkshopDetailsAndPay = async () => {
    let valid = true;

    if (!isAuthenticated || !isUserInfoExists) {
      if (!isVerified) {
        if (!firstName) {
          setfirstNameError(true);
          valid = false;
        } else {
          setfirstNameError(false);
        }

        if (!lastName) {
          setLastNameError(true);
          valid = false;
        } else {
          setLastNameError(false);
        }

        if (useEmail) {
          if (!phoneNumber) {
            setPhoneNumberError(true);
            valid = false;
          } else {
            setPhoneNumberError(false);
          }
        } else {
          if (!phoneNumber) {
            setPhoneNumberError(true);
            valid = false;
          } else {
            setPhoneNumberError(false);
          }

          if (!email) {
            setEmailError(true);
            valid = false;
          } else {
            setEmailError(false);
          }
        }

        if (!profileType) {
          setProfileTypeError(true);
          valid = false;
        }
      }
    }

    if (valid) {
      setIsLoading(true);
      try {
        let userDetailsUpdated = false;
        if (!isUserInfoExists) {
          userDetailsUpdated = await sumbitUserDetails();
        }

        if (userDetailsUpdated || isUserInfoExists) {
          const { paymentData, appliedCoupon, workshopPurchaseId } =
            await getWorkshopPayData();
          await initiateRazorpayPayment(
            paymentData,
            appliedCoupon,
            workshopPurchaseId
          );
        }
      } catch (error) {
        console.error("Error in flow:", error);
      }
    } else {
      setShowAlert(true);
      handleAddAlert(
        "Input Error",
        "Please fill in all mandatory fields before proceeding",
        "error",
        true
      );
    }
  };

  const handlePaymentSuccess = async (cart_id, workshopPurchaseId) => {
    try {
      const localData = {
        is_bypassed: false,
      };
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const res = await open_api_with_user_token(bearerLocalToken).patch(
        `/payment/pay/${cart_id}/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        const sessionData = sessionStorage.getItem("expertSession");
        if (sessionData) {
          const expertSessionData = JSON.parse(sessionData);
          postExpertUserPurchase(
            expertSessionData.expertslug,
            bearerLocalToken,
            workshopPurchaseId,
            null
          );
        }
        setPaymentSuccessful(true);
        setIsLoading(false);
        setDialogProps({
          status: "success",
          statusText: "Thank you",
          statusDescription:
            "You have successfully purchased the workshop. We will be sending you an email confirmation shortly.",
          statusIcon: successIcon,
          backgroundColor: "#FFFF",
        });
        setConfirmationDialogOpen(true);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handlePaymentFailure = async (response, cart_id) => {
    try {
      const res = await open_api_with_user_token(bearerLocalToken).patch(
        `/payment/pay/${cart_id}/`
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        console.error("Payment failed:", response.error);
        setPaymentSuccessful(true);
        setDialogProps({
          status: "failure",
          statusText: "Payment Failed",
          statusDescription:
            "There was an issue with the payment. Please try again or contact support at hello@myburgo.com if the problem persists.",
          statusIcon: FailedIcon,
          backgroundColor: "#FFFF",
        });
        setIsLoading(false);
        setConfirmationDialogOpen(true);
      }
    } catch (err) {
      //console.log(err);
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleGoogleSignInSuccess = (idToken) => {
    setUseEmail(true);
    getProfileDetailsWithToken(idToken);
  };

  const handleGoogleSignInFailure = (error) => {
    // Handle sign-in failure
    console.error(error);
  };

  const getProfileDetailsWithToken = async (idToken) => {
    const localData = {
      token: idToken,
    };

    try {
      const res = await open_api_without_auth.post(
        `/auth/google/oauth/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        if (res?.data?.data?.user != null) {
          setOtpValidated(true);
          const verified = res?.data?.data?.verified;
          if (verified === true) {
            setVerified(true);
          }
          const userDetailsId = res?.data?.data?.user?.id;
          const accessToken = res?.data?.data?.token?.access;
          setUserId(userDetailsId);
          setBearerToken(accessToken);
        }
        return isVerified;
      } else {
        setShowAlert(true);
        handleAddAlert("Login Error", res?.data?.data?.message, "error", true);
      }
    } catch (err) {
      //console.log(err);
    }
  };

  const handleCloseConfirmationDialog = (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      setConfirmationDialogOpen(false);
      resetAllStates();
    }
  };

  const resetAllStates = () => {
    setConfirmationDialogOpen(false);
    setUseEmail(false);
    setOtpRequested(false);
    setContactValue("");
    setPaymentSuccessful(false);
    setVerified(false);
    setIsLoading(false);
    setPhoneNumberError(false);
    setEmailError(false);
    setfirstNameError(false);
    setLastNameError(false);
    onClose();
    setOtp(Array(4).fill(""));
    setCouponFound(false);
    setCoupon([]);
    setDiscountAmount(0);
    setOtpValidated(false);
    setCountryCode("in");
    setProfileType("");
    setProfileTypeError(false);
    setMessage("");
    if (!isAuthenticated) {
      setEmail("");
      setPhoneNumber("");
      setfirstName("");
      setLastName("");
      setBearerToken("");
      setUserId(0);
    }
    window.location.reload();
  };

  return (
    <>
      <MuiDialog
        open={open && !isPaymentSuccessful}
        onClose={handleClose}
        maxWidth="md"
        fullWidth={true}
        fullScreen={fullScreen}
      >
        {isLoading && <LoadingOverlay message={message} />}
        <DialogContent
          sx={{ padding: 0, position: "relative", background: "#e7e7e7" }}
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContainer>
            <LeftSection>
              <img src={image} alt="Dialog" />
              <h1>{title}</h1>
              <h2>
                {expertDetailsList.length > 0 && (
                  <>
                    by{" "}
                    {expertDetailsList
                      .slice(0, expertDetailsList.length - 1)
                      .map((expert, index) => (
                        <span key={index}>
                          {expert.user?.first_name} {expert.user?.last_name}
                          {index < expertDetailsList.length - 2 ? ", " : ""}
                        </span>
                      ))}
                    {expertDetailsList.length > 1 && (
                      <>
                        {" and "}
                        {
                          expertDetailsList[expertDetailsList.length - 1].user
                            ?.first_name
                        }{" "}
                        {
                          expertDetailsList[expertDetailsList.length - 1].user
                            ?.last_name
                        }
                      </>
                    )}
                  </>
                )}
              </h2>
              {isLive ? <p>{description}</p> : <p>{description}</p>}
            </LeftSection>
            <RightSection>
              <h1>Payment details</h1>
              <h2>Complete your purchase by providing your payment details.</h2>
              {!isAuthenticated ? (
                <Card>
                  {otpValidated ? (
                    <>
                      <SuccessMessage>
                        <CheckCircleIcon color="success" />
                        <h3>
                          Your {useEmail ? "email" : "phone number"}{" "}
                          {useEmail ? email : `+91 ${phoneNumber}`} has been
                          verified successfully
                        </h3>
                      </SuccessMessage>
                      {!isVerified ? (
                        <div>
                          <Label>First Name:</Label>
                          <InputField
                            name="firstName"
                            type="text"
                            placeholder="Enter your first name"
                            value={firstName}
                            onChange={handlefirstNameChange}
                            style={{
                              borderColor: firstNameError ? "red" : "#ccc",
                            }}
                          />
                          {firstNameError && (
                            <ErrorText>This field is required</ErrorText>
                          )}
                          <Label>Last Name:</Label>
                          <InputField
                            name="lastName"
                            type="text"
                            placeholder="Enter your last name"
                            value={lastName}
                            onChange={handlelastNameChange}
                            style={{
                              borderColor: lastNameError ? "red" : "#ccc",
                            }}
                          />
                          {lastNameError && (
                            <ErrorText>This field is required</ErrorText>
                          )}
                          {useEmail ? (
                            <>
                              <Label>Phone Number:</Label>
                              <InputField
                                name="phoneNumber"
                                type="number"
                                placeholder="Please enter your phone number"
                                value={phoneNumber}
                                onChange={handlePhoneNumberInputChange}
                                style={{
                                  borderColor: phoneNumberError
                                    ? "red"
                                    : "#ccc",
                                }}
                              />
                              {phoneNumberError && (
                                <ErrorText>This field is required</ErrorText>
                              )}
                            </>
                          ) : (
                            <>
                              <Label>Email:</Label>
                              <InputField
                                name="email"
                                type="email"
                                placeholder="Enter your email id"
                                value={email}
                                onChange={handleEmailInputChange}
                                style={{
                                  borderColor: emailError ? "red" : "#ccc",
                                }}
                              />
                              {emailError && (
                                <ErrorText>This field is required</ErrorText>
                              )}
                            </>
                          )}
                          <Label htmlFor="profileType">
                            Select Profile Type:
                          </Label>
                          <Select
                            id="profileType"
                            name="profileType"
                            value={
                              profileTypes.find(
                                (type) => type.title === profileType
                              )?.id || ""
                            }
                            onChange={handleProfileTypeChange}
                            style={{
                              borderColor: profileTypeError ? "red" : "#ccc",
                            }}
                          >
                            <option value="">Select a profile type</option>
                            {profileTypes.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.title}
                              </option>
                            ))}
                          </Select>
                          {profileTypeError && (
                            <ErrorText>This field is required</ErrorText>
                          )}
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : otpRequested ? (
                    <>
                      <h3>
                        A 4-digit code is sent to{" "}
                        {useEmail ? contactValue : `+91 ${phoneNumber}`}
                      </h3>
                      <OtpInputContainer>
                        {Array.from({ length: 4 }).map((_, index) => (
                          <input
                            key={index}
                            type="text"
                            maxLength="1"
                            value={otp[index]}
                            onChange={(e) => handleOTPInputChange(e, index)}
                            onKeyDown={(e) => handleOTPKeyDown(e, index)}
                            ref={(el) => (inputRefs.current[index] = el)}
                          />
                        ))}
                      </OtpInputContainer>
                      <Button onClick={handleSubmitOTP}>Submit</Button>
                      <div style={{ textAlign: "center", marginTop: "20px" }}>
                        {resentOTP ? (
                          <div>
                            <p>Resend OTP in {countdown} seconds</p>
                          </div>
                        ) : (
                          <div>
                            <ResendOtpLink onClick={handleResendOTP}>
                              Resend OTP
                            </ResendOtpLink>
                            <p
                              style={{ marginTop: "8px", textAlign: "center" }}
                            >
                              Didn't receive a code? Click on Resend to receive
                              OTP
                            </p>
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {useEmail ? (
                        <>
                          <h3>Login with Google</h3>
                          <GoogleSignIn
                            onSignInSuccess={handleGoogleSignInSuccess}
                            onSignInFailure={handleGoogleSignInFailure}
                          />
                          <Button onClick={handleRequestOTP}>
                            Request OTP
                          </Button>
                          <p style={{ marginTop: "8px", textAlign: "center" }}>
                            Or,{" "}
                            <a
                              href="#"
                              style={{ color: "#1a73e8" }}
                              onClick={handleToggle}
                            >
                              Verify via phone OTP
                            </a>
                          </p>
                        </>
                      ) : (
                        <>
                          <h3>Verify using Phone Number</h3>
                          <OtpContainer
                            style={{
                              borderColor: phoneNumberError ? "red" : "#ccc",
                            }}
                          >
                            <PhoneInput
                              country={"in"}
                              value={phoneNumber}
                              onChange={handlePhoneInputChange}
                              onlyCountries={["in"]}
                              disableDropdown
                              disableCountryCode
                              placeholder="Enter your phone number"
                              inputStyle={{
                                border: "none",
                                outline: "none",
                                padding: "8px 8px 8px 44px",
                                flex: "1",
                                borderRadius: "0 8px 8px 0",
                                overflow: "auto",
                                width: "100%",
                              }}
                            />
                          </OtpContainer>
                          {phoneNumberError && (
                            <ErrorText>
                              Please enter a valid phone number
                            </ErrorText>
                          )}
                          <Button onClick={handleRequestOTP}>
                            Request OTP
                          </Button>
                          {/* TODO implement this later */}
                          {/* 
                        <p style={{ marginTop: "8px", textAlign: "center" }}>
                          Or,{" "}
                          <a
                            href="#"
                            style={{ color: "#1a73e8" }}
                            onClick={handleToggle}
                          >
                            Verify via Email
                          </a>
                        </p> */}
                        </>
                      )}
                    </>
                  )}
                </Card>
              ) : (
                <Card>
                  {!isUserInfoExists ? (
                    <div>
                      <Label>First Name:</Label>
                      <InputField
                        name="firstName"
                        type="text"
                        placeholder="Enter your first name"
                        value={firstName}
                        onChange={handlefirstNameChange}
                        style={{
                          borderColor: firstNameError ? "red" : "#ccc",
                        }}
                      />
                      {firstNameError && (
                        <ErrorText>This field is required</ErrorText>
                      )}
                      <Label>Last Name:</Label>
                      <InputField
                        name="lastName"
                        type="text"
                        placeholder="Enter your last name"
                        value={lastName}
                        onChange={handlelastNameChange}
                        style={{
                          borderColor: lastNameError ? "red" : "#ccc",
                        }}
                      />
                      {lastNameError && (
                        <ErrorText>This field is required</ErrorText>
                      )}
                      {useEmail ? (
                        <>
                          <Label>Phone Number:</Label>
                          <InputField
                            name="phoneNumber"
                            type="number"
                            placeholder="Please enter your phone number"
                            value={phoneNumber}
                            onChange={handlePhoneNumberInputChange}
                            style={{
                              borderColor: phoneNumberError ? "red" : "#ccc",
                            }}
                          />
                          {phoneNumberError && (
                            <ErrorText>This field is required</ErrorText>
                          )}
                        </>
                      ) : (
                        <>
                          <Label>Email:</Label>
                          <InputField
                            name="email"
                            type="email"
                            placeholder="Enter your email id"
                            value={email}
                            onChange={handleEmailInputChange}
                            style={{
                              borderColor: emailError ? "red" : "#ccc",
                            }}
                          />
                          {emailError && (
                            <ErrorText>This field is required</ErrorText>
                          )}
                        </>
                      )}
                      <Label htmlFor="profileType">Select Profile Type:</Label>
                      <Select
                        id="profileType"
                        name="profileType"
                        value={
                          profileTypes.find(
                            (type) => type.title === profileType
                          )?.id || ""
                        }
                        onChange={handleProfileTypeChange}
                        style={{
                          borderColor: profileTypeError ? "red" : "#ccc",
                        }}
                      >
                        <option value="">Select a profile type</option>
                        {profileTypes.map((type) => (
                          <option key={type.id} value={type.id}>
                            {type.title}
                          </option>
                        ))}
                      </Select>
                      {profileTypeError && (
                        <ErrorText>This field is required</ErrorText>
                      )}
                    </div>
                  ) : (
                    <SuccessMessage>
                      <CheckCircleIcon color="success" />
                      <h3>
                        You are logged in as {userName} having the phone number{" "}
                        {useEmail ? email : `+91 ${phoneNumber}`}
                      </h3>
                    </SuccessMessage>
                  )}
                </Card>
              )}

              {otpValidated || isAuthenticated ? (
                <Card>
                  <h3>Have a coupon code? Enter here</h3>
                  <CouponContainer>
                    <div className="couponHandler">
                      <CouponInput
                        type="text"
                        placeholder="Enter Coupon Code"
                        value={coupon}
                        onChange={(event) =>
                          handleCouponChange(event, setCoupon)
                        }
                      />
                      <ApplyButton onClick={handleApplyCoupon}>
                        Apply
                      </ApplyButton>
                    </div>
                    {couponFound && (
                      <CongratulationsBanner
                        priceSymbol={priceSymbol}
                        discount={discountAmount}
                      />
                    )}
                  </CouponContainer>
                </Card>
              ) : (
                <></>
              )}
              <ServiceCard>
                <div className="service-details">
                  <div className="service-info">
                    <h4>Service</h4>
                    <p>{title}</p>
                    {couponFound && <p>Coupon discount</p>}
                  </div>
                  <div className="service-price">
                    <div style={{ flexDirection: "column" }}>
                      <div
                        style={{
                          paddingBottom: couponFound ? "10px" : "0px",
                        }}
                      >
                        <span className="original-price">
                          {priceSymbol}
                          {mrpPrice}
                        </span>
                        <span className="discounted-price">
                          {priceSymbol}
                          {sellingPrice}
                        </span>
                      </div>
                      {couponFound && (
                        <span
                          className="discounted-price"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          - {priceSymbol}
                          {discountAmount}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="divider"></div>
                <AmountCard>
                  <div className="amount-left">
                    <h4>Amount to be paid</h4>
                  </div>
                  <div className="amount-right">
                    <span className="discounted-price">
                      {priceSymbol}
                      {couponFound ? newSellingPrice : sellingPrice}
                    </span>
                  </div>
                </AmountCard>
              </ServiceCard>

              {otpValidated || isAuthenticated ? (
                <PayButton
                  className="payButton"
                  onClick={fetchWorkshopDetailsAndPay}
                  disabled={(couponFound ? newSellingPrice : sellingPrice) < 0}
                >
                  Proceed to Pay
                </PayButton>
              ) : (
                <></>
              )}
            </RightSection>
            {showAlert &&
              alerts.map((alert) => (
                <CustomAlert
                  key={alert.id}
                  title={alert.title}
                  message={alert.message}
                  onClose={() => handleRemoveAlert(alert.id)}
                  autoClose={alert.autoClose}
                  type={alert.type}
                />
              ))}
          </DialogContainer>
        </DialogContent>
      </MuiDialog>

      {isConfirmationDialogOpen && (
        <ConfirmationDialog
          open={isConfirmationDialogOpen}
          onClose={handleCloseConfirmationDialog}
          image={image}
          title={title}
          description={description}
          {...dialogProps}
          redirect={"/my-learning"}
          countdownDuration={8}
          whatsappLink={whatsapplink}
        />
      )}
    </>
  );
};

export default PaymentDialog;
