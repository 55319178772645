import React, { useEffect, useState } from "react";
import styles from "./BlogInfo.module.css";
import innerBLogBaby from "../../Assets/innerBLogBaby.png";
import blogStar from "../../Assets/blogStar.png";
import infoImg from "../../Assets/infoImg.png";
import blogClock from "../../Assets/blogClock.png";
import blogShare from "../../Assets/blogShare.png";
import mobileClock from "../../Assets/mobileClock.png";
import mobileShare from "../../Assets/mobileShare.png";
import catchBaby1 from "../../Assets/catchBaby1.png";
import catchBaby2 from "../../Assets/catchBaby2.png";
import fullImg from "../../Assets/fullImg.png";
import bottomLeft from "../../Assets/bottomLeft.png";
import bottomRight from "../../Assets/bottomRight.png";
import ReviewCards from "../ReviewCards/ReviewCards";
import InnerCards from "../InnerCards/InnerCards";

import forword from "../../Assets/forword.png";
import { useParams } from "react-router-dom";
import { open_api } from "../../Utils/network";
import { Rating } from "@mui/material";

function BlogInfo({ data }) {
  // console.log(data, "BlogInfo");
  const [blogReviews, setBlogReviews] = useState();
  const [blogMain, setBlogMain] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      getBlogComments(id);
    }
  }, [id]);

  const getBlogComments = (id) => {
    open_api
      .get(`blog/pages/${id}/comments/`)
      .then((res) => {
        // console.log(res?.data?.data , "getBlogComments");
        setBlogReviews(res?.data?.data);
        setBlogMain(res?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className={styles.container}>
        <div className={styles.topContainer}>
          <div className={styles.top}>
            <div className={styles.left}>
              <img
                src={
                  data?.header_image_url?.url
                    ? data?.header_image_url?.url
                    : innerBLogBaby
                }
              ></img>
            </div>
            <div className={styles.right}>
              <h1 className={styles.righth1}>
                {/* Why do babies throw tantrum so often? */}
                {data?.title}
              </h1>
              <span>{data?.published_date}</span>

              <div className={styles.infoContainer}>
                <div className={styles.info}>
                  <div className={styles.img}>
                    <img src={infoImg}></img>
                  </div>
                  <div className={styles.desc}>
                    <p className={styles.descp}>{data?.authors[0]?.name}</p>
                    <h1 className={styles.desch1}>Mental Health Expert </h1>
                    <div className={styles.rating}>
                        <Rating name="read-only" value={2} readOnly />
                    </div>
                    {/* <div className={styles.rating}>
                      <span>Rating: 4/5</span>

                      {data1.map((item) => {
                        return (
                          <div className={styles.star}>
                            <img src={blogStar}></img>
                          </div>
                        );
                      })}
                    </div> */}
                  </div>
                </div>

                <div className={styles.comments}>
                  <p className={styles.commentp}>{blogMain?.count} Comments</p>
                  <div className={styles.baseline}>
                    <div className={styles.watch}>
                     <div className={styles.clockDesktop}>
                        <img src={mobileClock}></img>
                      </div>

                       <div className={styles.clockMobile}>
                        <img src={mobileClock}></img>
                      </div>

                      <span>{data?.read_time} Minute </span>
                    </div> 
                    <div className={styles.watch}>
                      <div className={styles.clockDesktop}>
                        <img src={forword}></img>
                      </div>

                      <div className={styles.clockMobile}>
                        <img src={forword}></img>
                      </div>

                      <span>Share</span>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div className={styles.paras}>
            
            {
              data?.body?.map((v) => {

                return(
                  <div dangerouslySetInnerHTML={{__html : v?.value }}>

            </div>
                )
              })

            }
            
          </div>
          {/* <div className={styles.middle}>
            <div className={styles.catchBaby}>
              <img src={catchBaby1}></img>
            </div>
            <div className={styles.catchBaby}>
              <img src={catchBaby2}></img>
            </div>
          </div> */}
          {/* <div className={styles.paras}>
            <ol type="1">
              <li>
                Hunger or Fatigue: Just like adults, babies and toddlers can get
                irritable when they're hungry or tired. Ensuring they have
                regular meals, snacks, and naps can help prevent tantrums.
              </li>
              <li>
                Overstimulation or Overwhelm: Too much noise, bright lights, or
                a crowded environment can overwhelm a baby's developing senses,
                leading to stress and tantrums.
              </li>
              <li>
                Desire for Independence: As babies grow, they begin to assert
                their independence. When they want to explore or do things on
                their own but are restricted, they may react with frustration.
              </li>
              <li>
                Lack of Predictability: Babies and toddlers thrive on routine
                and predictability. Changes in routine or unexpected events can
                be unsettling for them, leading to tantrums.
              </li>
              <li>
                Emotional Expression: Babies and toddlers are still learning to
                regulate their emotions. Tantrums can be a way for them to
                express frustration, anger, or disappointment when things don't
                go their way.
              </li>
              <li>
                Teething: Teething can be a painful experience for babies,
                causing discomfort and irritability, which may contribute to
                tantrums.
              </li>
              <li>
                Illness or Discomfort: If a baby is not feeling well or is
                experiencing discomfort (such as diaper rash), they may express
                their distress through tantrums.
              </li>
            </ol>
          </div> */}
        </div>
        {/* <div className={styles.fullImg}>
          <img src={fullImg}></img>
        </div> */}
        <div className={styles.bottom}>
         {/*  <p className={styles.para1}>
            It's important for parents and caregivers to respond with patience
            and understanding during tantrums. Offering comfort, trying to
            identify the underlying cause, and teaching age-appropriate coping
            skills can help manage and reduce tantrum frequency over time.
          </p>
          <p className={styles.paras2}>
            {" "}
            Babies are delightful bundles of joy, bringing love and laughter
            into our lives. However, they also come with their fair share of
            challenges, one of the most notorious being tantrums. Whether it's
            in the middle of a crowded supermarket or during bedtime routine,
            tantrums can leave parents feeling overwhelmed and exhausted. But
            why do babies throw tantrums so often? Let's explore the fascinating
            science behind this common behaviour.
          </p>

          <p className={styles.paras2}>
            {" "}
            Communication Challenges: Babies are not born with the ability to
            express themselves verbally. Instead, they rely on crying, facial
            expressions, and body language to communicate their needs and
            emotions. When they are hungry, tired, uncomfortable, or
            overstimulated, they lack the words to convey their feelings
            effectively. As a result, they resort to crying, screaming, and
            throwing tantrums as a way to express their frustration.
          </p>
          <p className={styles.paras2}>
            Emotional Regulation:Babies are still learning to regulate their
            emotions. They experience a wide range of feelings, including joy,
            sadness, anger, and fear, but lack the maturity and coping
            mechanisms to manage these emotions effectively. When overwhelmed by
            intense feelings, such as disappointment or anger, babies may resort
            to tantrums as a way to release pent-up emotions and seek comfort
            from caregivers.
          </p>
          <p className={styles.paras2}>
            Sensory Overload:Babies have highly sensitive nervous systems,
            making them susceptible to sensory overload. Loud noises, bright
            lights, unfamiliar surroundings, and overwhelming stimuli can
            trigger sensory overwhelm, leading to meltdowns and tantrums.
            Additionally, changes in routine, transitions between activities,
            and unfamiliar faces can further exacerbate sensory sensitivities
            and provoke tantrums.
          </p> */}

          {/* <div className={styles.bottomImg}>
            <div className={styles.Images}>
              <img src={bottomLeft}></img>
            </div>
            <div className={styles.Images}>
              <img src={bottomRight}></img>
            </div>
          </div> */}
          <hr></hr>
        </div>
      </div>
      <section className={styles.reviews}>
        <h1 className={styles.reviewsh1}>Ratings & Reviews</h1>
        <p className={styles.numbers}>{blogMain?.count} Reviews</p>
        {blogReviews?.map((item) => {
          return <ReviewCards  data={item}/>;
        })}
        {/* <p className={styles.seeMore}>See more</p> */}
      </section>
    </>
  );
}

export default BlogInfo;
const data1 = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }];

const cards = [{ id: 1 }, { id: 2 }, { id: 3 }];
