import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import LoginDialog from "../Login/LoginCard";
import "./Layout.css";

function Layout() {
  const location = useLocation();
  const navigate = useNavigate();

  const hideHeaderFooterRoutes = ["/login"];

  const shouldHideHeaderFooter = hideHeaderFooterRoutes.includes(
    location.pathname
  );

  const handleLoginSuccess = (userName) => {
    navigate("/");
    window.location.reload();
  };

  return (
    <div className="layout-container">
      {/* Conditionally render header and footer based on the current path */}
      {!shouldHideHeaderFooter && (
        <Header handleLoginSuccess={handleLoginSuccess} />
      )}

      <main className="content">
        <Outlet />
      </main>

      {!shouldHideHeaderFooter && <Footer />}
    </div>
  );
}

export default Layout;
