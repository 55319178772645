import React from "react";
import styles from "./index.module.css";
import qrimg from "../../Assets/expert_page/appQrCode.png";
import appIcon1 from "../../Assets/Logo_icons/apple.png";
import appIcon2 from "../../Assets/Logo_icons/android.png";
import vector from "../../Assets/Logo_icons/downloadAppvector.png";
import appAdv from "../../Assets/expert_page/appAdv.png";

function DownloadeApp() {
  return (
    <>
      <section className={styles.downloadeApp}>
        <div className={styles.containerLeft}>
          <h4>Download our APP Now</h4>
          <div className={styles.downloadbox}>
            <div className={styles.appImgcontainer}>
              <img src={appIcon1} />
            </div>
            <div className={styles.boxText}>
              <h6>Download on the</h6>
              <h5>App Store </h5>
            </div>
          </div>
          <div className={styles.downloadbox}>
            <div className={styles.appImgcontainer}>
              <img src={appIcon2} />
            </div>
            <div className={styles.boxText}>
              <h6>Download on the</h6>
              <h5>Google Play</h5>
            </div>
          </div>
          {/* <div className={styles.qrCode}>
            <img src={qrimg} />
          </div> */}
        </div>
        <div className={styles.containeCenter}>
          <img src={vector} />
        </div>
        <div className={styles.containerRight}>
          <img src={appAdv} />
        </div>
      </section>
    </>
  );
}

export default DownloadeApp;
