import React, { useState, useEffect } from "react";
import "./Account.css";
import { open_api_with_user_token } from "../../Utils/network";
import {
  fetchUserDetails,
  addAlert,
  fetchProfileTypes,
  removeAlert,
} from "../Dialog/helper";
import styled from "styled-components";
import CustomAlert from "../AlertDialog/CustomAlert";

export const ErrorText = styled.label`
  color: red;
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 500;
  margin-top: 0;
`;

export const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  font-size: 0.875rem;
  &:focus {
    border-color: #6366f1;
    box-shadow: 0 0 0 2px rgba(99, 102, 241, 0.5);
  }
`;

const Account = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [profileType, setProfileType] = useState(""); // Set profileType to ID
  const [profileTypeError, setProfileTypeError] = useState(false);
  const [profileTypes, setProfileTypes] = useState([]);
  const [token, setToken] = useState("");
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [userId, setUserId] = useState("");

  // Fetch user details on component mount
  useEffect(() => {
    const getAccountDetails = async () => {
      const token = localStorage.getItem("bearerToken");
      const storedUserId = localStorage.getItem("userId");
      setToken(token);
      setUserId(storedUserId);
      try {
        const userInfo = await fetchUserDetails(token, storedUserId);

        // Fetch profile types from API
        await fetchProfileTypes(token, setProfileTypes);

        // Set user details
        setPhone(userInfo?.phone);
        setEmail(userInfo?.email);
        setFirstName(userInfo?.first_name);
        setLastName(userInfo?.last_name);

        // Set profile type by ID
        setProfileType(userInfo?.userprofile?.register_as?.id || "");
      } catch (error) {
        console.error("Failed to fetch user details", error);
      }
    };

    getAccountDetails();
  }, []);

  const handleProfileTypeChange = (e) => {
    setProfileType(e.target.value);
    setProfileTypeError(false);
  };

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };
  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  const handleSave = async () => {
    if (!firstName || !lastName || !phone || !email || !profileType) {
      setFirstNameError(!firstName);
      setLastNameError(!lastName);
      setPhoneError(!phone);
      setEmailError(!email);
      setProfileTypeError(!profileType);
      return;
    }

    await sumbitUserDetails();

    // Revert to view mode after saving
    setIsEditing(false);
  };

  const sumbitUserDetails = async () => {
    let registerAsId, title;
    switch (profileType) {
      case 1:
        registerAsId = 1;
        title = "Mrs";
        break;
      case 2:
        registerAsId = 2;
        title = "Mr";
        break;
      case 3:
        registerAsId = 3;
        title = "Mr";
        break;
      case 4:
        registerAsId = 4;
        title = "Mrs";
        break;
      default:
        registerAsId = 1;
        title = "Mr/Mrs";
    }

    const userDetails = {
      id: userId,
      phone: phone,
      first_name: firstName,
      last_name: lastName,
      email: email,
      register_as_id: profileType,
      title: title,
    };
    try {
      const res = await open_api_with_user_token(token).patch(
        `/profiles/update_profile/`,
        userDetails
      );

      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        localStorage.setItem("userName", firstName + " " + lastName);
        setShowAlert(true);
        handleAddAlert(
          "Successful",
          "Successfully updated the account details",
          "success",
          true
        );
        return true;
      } else if (res?.status === 400) {
        if (res?.data?.email != null) {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data?.email, "error", true);
        }
      }
      return false;
    } catch (err) {
      console.error("Error in sumbitUserDetails:", err);
      return false;
    }
  };

  return (
    <div className="account-container">
      <div className="account-header">
        <h2>Account</h2>
        <i
          className="fas fa-pencil-alt edit-icon"
          onClick={() => setIsEditing(true)}
        />
      </div>
      <form className="account-form">
        <div className="form-group">
          <label htmlFor="first-name">First Name:</label>
          <input
            type="text"
            id="first-name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            disabled={!isEditing}
            className={!isEditing ? "disabled-input" : ""}
            style={{
              borderColor: firstNameError ? "red" : "#ccc",
            }}
          />
          {firstNameError && <ErrorText>This field is required</ErrorText>}
        </div>
        <div className="form-group">
          <label htmlFor="last-name">Last Name:</label>
          <input
            type="text"
            id="last-name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            disabled={!isEditing}
            className={!isEditing ? "disabled-input" : ""}
            style={{
              borderColor: lastNameError ? "red" : "#ccc",
            }}
          />
          {lastNameError && <ErrorText>This field is required</ErrorText>}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled={!isEditing}
            className={!isEditing ? "disabled-input" : ""}
            style={{
              borderColor: emailError ? "red" : "#ccc",
            }}
          />
          {emailError && <ErrorText>This field is required</ErrorText>}
        </div>
        <div className="form-group">
          <label htmlFor="phone-number">Phone Number:</label>
          <input
            type="tel"
            id="phone-number"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            disabled={true}
            className="disabled-input"
            style={{
              borderColor: phoneError ? "red" : "#ccc",
            }}
          />
          {phoneError && <ErrorText>This field is required</ErrorText>}
        </div>
        <div className="form-group">
          <label htmlFor="profileType">Register As:</label>
          <Select
            id="profileType"
            name="profileType"
            value={profileType} // Ensure this is set to the ID
            onChange={handleProfileTypeChange}
            disabled={!isEditing}
            style={{
              borderColor: profileTypeError ? "red" : "#ccc",
            }}
            className={!isEditing ? "disabled-input" : ""}
          >
            <option value="">Select a profile type</option>
            {profileTypes.map((type) => (
              <option key={type.id} value={type.id}>
                {type.title}
              </option>
            ))}
          </Select>
          {profileTypeError && <ErrorText>This field is required</ErrorText>}
        </div>
        {isEditing && (
          <button type="button" className="button-1" onClick={handleSave}>
            Save
          </button>
        )}
      </form>
      {showAlert &&
        alerts.map((alert) => (
          <CustomAlert
            key={alert.id}
            title={alert.title}
            message={alert.message}
            onClose={() => handleRemoveAlert(alert.id)}
            autoClose={alert.autoClose}
            type={alert.type}
          />
        ))}
    </div>
  );
};

export default Account;
