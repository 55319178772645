import React from "react";
import "./MyLearningPage.css";
import MyLearning from "../../Components/Mylearning/MyLearning";
import SliderBase from "../../Components/Slider/SliderBase";
import DownloadeApp from "../../Components/DownloadeApp/DownloadeApp";

const MyLearningPage = () => {
  return (
    <>
      <section className={"sliderContainer"}>
        <SliderBase page={""} />
      </section>
      <section className={"myLearningContainer"}>
        <h2>My Learning</h2>
        <MyLearning />
      </section>
      <section style={{ marginTop: "5%" }}>
        <DownloadeApp />
      </section>
    </>
  );
};

export default MyLearningPage;
