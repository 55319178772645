import React from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
} from "react-router-dom";
import Layout from "../Components/Layouts/Layout";
import HomePage from "../Pages/HomePage/HomePage";
import ExpertDetailsPage from "../Pages/ExpertDetailsPage/ExpertDetailsPage";
import CourseDetailsPage from "../Pages/CourseDetailsPage/CourseDetailsPage";
import ExpertListingPage from "../Pages/ExpertListingPage/ExpertListingPage";
import PrivacyPolicyPage from "../Pages/PrivacyPolicyPage/PrivacyPolicyPage";
import TermsAndCondition from "../Pages/TermsAndCondition/TermsAndCondition";
import Blogs from "../Pages/Blogs /Blogs ";
import PragnancyCare from "../Pages/Service/PragnancyCare";
import ChildCare from "../Pages/ChildCare/ChildCare";
import MentalHelth from "../Pages/MentalHelth/MentalHelth";
import InnerBlog from "../Pages/InnerBlog/InnerBlog";
import CancellationandRefundPolicy from "../Pages/CancellationandRefundPolicy/CancellationandRefundPolicy";
import ContactUs from "../Pages/ContactUs/ContactUs";
import LandingPage from "../Pages/LandingPage/LandingPage";
import ProfilePage from "../Pages/Profile/ProfilePage";
import Account from "../Components/Profile/Account";
import Orders from "../Components/Profile/Order";
import UpcomingSession from "../Components/Profile/Session/UpcomingSession";
import ExpertRedirect from "../Components/Redirect/ExpertRedirect";
import AccountDeletedPage from "../Pages/Profile/AccountDeletedPage";
import WorkshopFeedbackPage from "../Pages/FeedbackPage/WorkshopFeedbackPage";
import PageNotFound from "../Pages/PageNotFound/PageNotFound";
import SessionDataPage from "../Pages/FeedbackPage/SessionDataPage";
import RevenueDashboard from "../Components/Profile/RevenueDashboard";
import MyLearningPage from "../Pages/MyLearningPage/MyLearningPage";
import PrivateRoute from "./PrivateRoute";
import WorkshopPage from "../Pages/WorkshopPage/WorkshopPage";
import LoginPage from "../Pages/Profile/LoginPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />}>
      <Route index element={<HomePage />} />
      <Route path="/landingpage" element={<LandingPage />} />
      <Route
        path="/experts/:expertslug/workshop/:workshopSlug"
        element={<ExpertRedirect />}
      />
      <Route path="/workshop/:workshopSlug" element={<CourseDetailsPage />} />
      <Route
        path="/workshop/:workshopSlug/feedback"
        element={<WorkshopFeedbackPage />}
      />
      <Route
        path="/expert/:expertSlug/bookings/:bookingId/session-data"
        element={<SessionDataPage />}
      />
      <Route path="/experts" element={<ExpertListingPage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
      <Route path="/terms-and-condition" element={<TermsAndCondition />} />
      <Route path="/experts/:slug" element={<ExpertDetailsPage />} />
      <Route path="/:expertslug" element={<ExpertRedirect />} />
      <Route
        path="/cancellation-and-refund-policy"
        element={<CancellationandRefundPolicy />}
      />
      <Route path="/blogs" element={<Blogs />} />
      <Route path="/blogs/:id" element={<InnerBlog />} />
      <Route path="/pregnancy-care" element={<PragnancyCare />} />
      <Route path="/child-care" element={<ChildCare />} />
      <Route path="/mental-health" element={<MentalHelth />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/delete_account"
        element={
          <PrivateRoute>
            <AccountDeletedPage />
          </PrivateRoute>
        }
      />
      <Route
        path="/my-learning"
        element={
          <PrivateRoute>
            <MyLearningPage />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/my-learning/ondemand/:workshopSlug"
        element={
          <PrivateRoute>
            <WorkshopPage />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/my-learning/ondemand/:workshopSlug/chapter/:chapterId"
        element={
          <PrivateRoute>
            <WorkshopPage />
          </PrivateRoute>
        }
      ></Route>
      <Route
        path="/profile"
        element={
          <PrivateRoute>
            <ProfilePage />
          </PrivateRoute>
        }
      >
        <Route path="account" element={<Account />} />
        <Route path="orders" element={<Orders />} />
        <Route path="revenue/*" element={<RevenueDashboard />}>
          <Route
            path="overview"
            element={<RevenueDashboard page="overview" />}
          />
          <Route
            path="transactions"
            element={<RevenueDashboard page="transactions" />}
          />
          <Route index element={<Navigate to="overview" />} />
        </Route>
        <Route path="sessions/upcoming" element={<UpcomingSession />} />
        <Route index element={<Navigate to="account" />} />
      </Route>
      {/* <Route path="*" element={<Navigate to="/" LandingPage />} /> */}
      {<Route path="*" element={<PageNotFound />} />}
    </Route>
  )
);

function Mainroutes() {
  return <RouterProvider router={router} />;
}

export default Mainroutes;
