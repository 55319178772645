import React from "react";
import styles from "./ReviewCards.module.css";
import logoImg from "../../Assets/logoImg.png";
import star from "../../Assets/Star.png";
import emptyStar from "../../Assets/emptyStar.png";
import timeAgo from "../../Utils/TimeFunction";
import { Rating } from "@mui/material";

function ReviewCards({data}) {
  // console.log(data, "ReviewCards");

  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div className={styles.left}>
          <div className={styles.image}>
            <div>
              {" "}
              <img src={logoImg}></img>
            </div>
            <span>{data?.user?.first_name} {data?.user?.last_name}</span>
          </div>
          <div className={styles.stars}>
          <Rating name="read-only" value={data?.likes_count} readOnly />
            <div>
              {/* {data.map((item) => {
                return <img src={item.img}></img>;
              })} */}
            </div>
            <p>({data?.likes_count})</p>
          </div>
        </div>
        <div className={styles.right}>
          <p>{timeAgo(data?.created)}</p>
        </div>
      </div>
      <div className={styles.bottom}>
        <p>
          {data?.text}
        </p>
      </div>
    </div>
  );
}

export default ReviewCards;
const data = [
  { id: 1, img: star },
  { id: 2, img: star },
  { id: 3, img: star },
  { id: 4, img: star },
  { id: 5, img: emptyStar },
];
