import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./Workshop.css";
import ReactPlayer from "react-player";

// Collapsible Section for each Workshop section
const WorkshopSection = ({ section, onSelectChapter, activeChapterId }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="workshop-section">
      <div className="section-header" onClick={handleToggle}>
        <h4>
          Section {section.index}: {section.title}
        </h4>
        <span>{isOpen ? "▲" : "▼"}</span>
      </div>
      {isOpen && (
        <div className="section-content">
          {section.chapters.map((chapter) => (
            <div
              key={chapter.id}
              className={`chapter ${activeChapterId === chapter.id ? "active" : ""}`}
              onClick={() => onSelectChapter(chapter)}
            >
              <p>
                Chapter {chapter.index}: {chapter.title}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// Main Workshop Component
const Workshop = ({ curriculumData }) => {
  const navigate = useNavigate();
  const { workshopSlug, chapterId } = useParams();

  const [currentVideo, setCurrentVideo] = useState({
    url: "",
    title: "No video selected",
  });
  const [activeChapterId, setActiveChapterId] = useState(null);

  // Handle chapter selection
  const handleSelectChapter = (chapter) => {
    if (chapter.workshop_video && chapter.workshop_video.length > 0) {
      const video = chapter.workshop_video[0]; // Assuming the first video is the one to play

      setCurrentVideo({
        url: video.video_url,
        title: video.title || chapter.title,
      });
      setActiveChapterId(chapter.id);

      // Update URL with chapter ID
      navigate(`/my-learning/ondemand/${workshopSlug}/chapter/${chapter.id}`);
    } else {
      console.log("No video available for this chapter.");
    }
  };

  // Load the first chapter by default if no chapterId is provided
  useEffect(() => {
    if (curriculumData.length > 0) {
      if (chapterId) {
        // Find the chapter by its ID
        let foundChapter = null;
        curriculumData.forEach((section) => {
          const chapter = section.chapters.find(
            (ch) => ch.id === parseInt(chapterId)
          );
          if (chapter) {
            foundChapter = chapter;
          }
        });

        if (foundChapter && foundChapter.workshop_video.length > 0) {
          const video = foundChapter.workshop_video[0]; // Assuming first video
          setCurrentVideo({
            url: video.video_url,
            title: video.title || foundChapter.title,
          });
          setActiveChapterId(parseInt(chapterId));
        }
      } else {
        // No chapterId, load the first video by default
        const firstSection = curriculumData[0];
        if (firstSection && firstSection.chapters.length > 0) {
          const firstChapter = firstSection.chapters[0];
          if (
            firstChapter.workshop_video &&
            firstChapter.workshop_video.length > 0
          ) {
            const firstVideo = firstChapter.workshop_video[0];
            setCurrentVideo({
              url: firstVideo.video_url,
              title: firstVideo.title || firstChapter.title,
            });
            setActiveChapterId(firstChapter.id);

            // Navigate to the first chapter in the URL
            navigate(
              `/my-learning/ondemand/${workshopSlug}/chapter/${firstChapter.id}`
            );
          }
        }
      }
    }
  }, [chapterId, curriculumData, workshopSlug, navigate]);

  return (
    <div className="workshop-container">
      {/* Left Side - Video Player */}
      <div className="workshop-left">
        <div className="video-player-wrapper">
          {currentVideo.url ? (
            <ReactPlayer
              url={currentVideo.url}
              controls={true}
              width="100%"
              height="auto"
              onContextMenu={(e) => e.preventDefault()}
              config={{ file: { attributes: { controlsList: "nodownload" } } }}
            />
          ) : (
            <div>No video available</div>
          )}
        </div>
        <h2 className="video-title">{currentVideo.title}</h2>
        <p className="video-author">{currentVideo.author}</p>
      </div>

      {/* Right Side - Collapsible Section */}
      <div className="workshop-right">
        <div className="workshop-card-right">
          <h3>Workshop covers</h3>
          {Array.isArray(curriculumData) && curriculumData.length > 0 ? (
            curriculumData.map((section) => (
              <WorkshopSection
                key={section.id}
                section={section}
                onSelectChapter={handleSelectChapter}
                activeChapterId={activeChapterId}
              />
            ))
          ) : (
            <p>No curriculum data available.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Workshop;
