import React, { useState } from 'react'
import styles from "./index.module.css"
import bannerweb from "../../Assets/Desktop.png"
// import bannerTab from "../../Assets/"
// import bannermobile from "../../Assets/"
import logo from "../../Assets/White Logo.png"
import { Button } from '@mui/material'
import PropTypes from 'prop-types';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from '@react-spring/web';
import FormModal from '../../Components/FormModal/FormModal'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const Fade = React.forwardRef(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element.isRequired,
  in: PropTypes.bool,
  onClick: PropTypes.any,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
  ownerState: PropTypes.any,
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function LandingPage() {
  const [open, setOpen] = useState(false);
  const [sOpne, setSOpne] = useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handelalert = () => {
    setSOpne(true)
  }
  const handleAClose = () => setSOpne(false);

  return (
    <div className={styles.mainContainer}>
        <div className={styles.ImgContainer} >
            {/* <img src={bannerweb} alt='Banner' /> */}
           <div className={styles.textLogoContainer}>
              <div className={styles.logoContainer}>
                  <img src={logo} alt='burgo-logo' />
              </div>
              <p className={styles.tagLine}>your ultimate parenting partner</p>
              <h1 className={styles.comingsoon}> COMING SOON</h1>
              <Button className={styles.notifyBtn} onClick={handleOpen} variant="contained">
              join the list
              </Button>
           </div>
        </div>

        <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            TransitionComponent: Fade,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style} className={styles.modalBox}>
            <FormModal handelalert={handelalert}  handleClose={handleClose}/>
          </Box>
        </Fade>
      </Modal>
      <Snackbar open={sOpne}  autoHideDuration={6000} onClose={handleAClose}>
        <Alert
          // anchorOrigin="bottom"
          onClose={handleAClose}
          severity="success"
          variant="filled"
          sx={{ width: '100%' }}
        >
          Your request has been submitted successfully!
        </Alert>
      </Snackbar>
    </div>
  )
}

export default LandingPage