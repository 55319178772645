import React, { useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  open_api_with_user_token,
  open_api_without_auth,
} from "../../Utils/network";

const ExpertRedirect = () => {
  const { expertslug, workshopSlug } = useParams(); // Capture both parameters
  const navigate = useNavigate();
  const hasTrackedVisit = useRef(false);

  useEffect(() => {
    const trackVisit = async () => {
      if (hasTrackedVisit.current) return;

      hasTrackedVisit.current = true;

      const token = localStorage.getItem("bearerToken");
      const isUserLoggedIn = token !== null;
      const localData = {
        expert_slug: expertslug ?? "",
      };

      try {
        if (isUserLoggedIn) {
          await open_api_with_user_token(token).post(
            "/stats/track-visit/",
            localData
          );
        } else {
          await open_api_without_auth.post("/stats/track-visit/", localData);
        }

        sessionStorage.setItem(
          "expertSession",
          JSON.stringify({ expertslug: expertslug })
        );

        if (workshopSlug) {
          // Redirect to workshop details if workshopSlug is present
          navigate(`/workshop/${workshopSlug}`);
        } else {
          // Redirect to expert details page if only expertslug is present
          navigate(`/experts/${expertslug}`);
        }
      } catch (error) {
        console.error("Error:", error);
        navigate("/404");
      }
    };

    trackVisit();
  }, [expertslug, workshopSlug, navigate]);

  return (
    <div>Redirecting...</div> // Optional: show a loading or redirecting message
  );
};

export default ExpertRedirect;
