import React from 'react'
import styles from './InnerCards.module.css'
import BlogBbaby from "../../Assets/BlogBbaby.png"
import { Button } from '@mui/material'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { useNavigate } from 'react-router-dom';
function IneerCards({data}) {
    const navigate = useNavigate();
    // console.log(data, "jhkfsdhiud");
    const handleBlogNav = (id) => {
        navigate(`/blogs/${id}`)
      }
    return (
        <div className={styles.container}>
            <div className={styles.left}>

                <div className={styles.img}>

                    <img src={BlogBbaby}></img>
                </div>
                {/* < p className={styles.label}>TRENDING</p> */}
            </div>
            <div className={styles.right}>
                <div className={styles.top}>
                    <h2>{data?.title}</h2>
                    <span>{data?.published_date}</span>
                    <p>{data?.intro}
                    </p>
                </div>
                <Button className={styles.readMore} onClick={() => handleBlogNav(data?.id)} endIcon={<KeyboardDoubleArrowRightIcon className={styles.endIcon} />}>READ MORE  </Button>
            </div>


        </div>
    )
}

export default IneerCards