import styled from "styled-components";

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  overflow: hidden;
  max-width: 900px;
  margin: auto;
  width: 100%;

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    flex-direction: column;
  }
`;
