import styled from "styled-components";

export const Container = styled.div`
  width: 91%;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;

  @media only screen and (max-width: 767px) and (min-width: 320px) {
    background-color: white;
    padding: 0;
    width: 100%;
  }
`;

export const DateList = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px 0;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const DateItem = styled.div`
  min-width: 60px;
  padding: 10px;
  margin: 0 5px;
  text-align: center;
  border-radius: 8px;
  background-color: ${(props) => (props.selected ? "#FF67A1" : "#fff")};
  color: ${(props) => (props.selected ? "#fff" : "#000")};
  border: ${(props) => (props.selected ? "none" : "1px solid #ccc")};
  cursor: pointer;
  &:hover {
    background-color: #ff67a1;
    color: #fff;
  }
`;

export const TimeSlots = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 30%;
`;

export const TimeSlot = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: ${(props) =>
    props.available ? (props.selected ? "#FF67A1" : "#fff") : "#ccc"};
  color: ${(props) =>
    props.available ? (props.selected ? "#fff" : "#000") : "#fff"};
  cursor: ${(props) => (props.available ? "pointer" : "not-allowed")};
  border: ${(props) =>
    props.available && props.selected ? "2px solid #FF67A1" : "1px solid #ccc"};
  &:hover {
    background-color: ${(props) =>
      props.available ? (props.selected ? "#FF67A1" : "#f5f5f5") : "#ccc"};
    border-color: ${(props) => (props.available ? "#FF67A1" : "#ccc")};
  }
`;

export const Duration = styled.div`
  font-weight: 600;
  align-self: center;
  color: ${(props) =>
    props.available ? (props.selected ? " #fff" : "#7a53ff") : "#7a53ff"};
`;

export const ScrollButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  img {
    width: 50px;
  }
`;

export const ProceedButton = styled.button`
  width: 100%;
  padding: 15px;
  margin-top: 20px;
  background-color: #ff67a1;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; // Adjust the height as needed
`;

export const CenteredImage = styled.img`
  width: 70%;
`;
