import { useEffect, useState } from "react";

const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [userName, setUserName] = useState("");
  const [userId, setUserId] = useState("");
  const [bearerToken, setBearerToken] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("bearerToken");
    const storedUserName = localStorage.getItem("userName");
    const storedUserId = localStorage.getItem("userId");

    if (token && storedUserName && storedUserId) {
      setIsAuthenticated(true);
      setUserName(storedUserName);
      setUserId(storedUserId);
      setBearerToken(token);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("bearerToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("userId");
    setIsAuthenticated(false);
    setUserName("");
    setUserId("");
    setBearerToken("");
  };

  return {
    isAuthenticated,
    setIsAuthenticated,
    userName,
    userId,
    bearerToken,
    logout,
  };
};

export default useAuth;
