import React from "react";
import { signInWithGooglePopup } from "./firebaseConfig";

const GoogleSignIn = ({ onSignInSuccess, onSignInFailure }) => {
  const handleSignIn = () => {
    const response = signInWithGooglePopup()
      .then((result) => {
        return result.user.getIdToken().then((idToken) => {
          console.log("ID Token:", idToken);
          onSignInSuccess(idToken);
        });
      })
      .catch((error) => {
        console.error("Google sign-in failed", error);
        onSignInFailure(error);
      });
  };

  return <button onClick={handleSignIn}>Login with Google</button>;
};

export default GoogleSignIn;
