import axios from "axios";
// export const base_url = "http://43.204.99.206/";
// export const base_url = "https://myburgo.com/api";
//export const base_url = "https://api.myburgo.com/"; // staging api
export const base_url = "https://prod.myburgo.com/"; // prod api
// export const base_url = "http://127.0.0.1:8000/";

export const open_api = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});

export const open_api_with_user_token = (token) =>
  axios.create({
    baseURL: base_url,
    timeout: 300000,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": `application/json`,
    },
    validateStatus: (status) => status < 500,
    transformRequest: [
      function (data, headers) {
        return JSON.stringify(data);
      },
    ],
  });

export const open_api_without_auth = axios.create({
  baseURL: base_url,
  timeout: 300000,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: (status) => status < 500,
  transformRequest: [
    function (data, headers) {
      return JSON.stringify(data);
    },
  ],
});
