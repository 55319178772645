import React, { useEffect, useState } from "react";
import ClientreviewCard from "../ClientreviewCard/ClientreviewCard";
import { open_api } from "../../Utils/network";

function ExpertReview({ expertId }) {
  const [expertReview, setExpertReview] = useState([]);
  useEffect(() => {
    if (expertId) {
      ReviewCard();
    }
  }, [expertId]);
  const ReviewCard = () => {
    open_api
      .get(`home/testimonials?expert=${expertId}`)
      .then((res) => {
        // console.log(res?.data?.results);
        setExpertReview(res?.data?.results);
      })
      .catch((err) => {
        //console.log(err);
      });
  };
  // console.log(expertReview, "wneku7difushjfn");
  return (
    <>
      <ClientreviewCard data={expertReview} />
    </>
  );
}

export default ExpertReview;
