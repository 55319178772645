import React from "react";
import LoginCard from "../../Components/Login/LoginCard";
import "./LoginPage.css"; // Importing the CSS for styling
import myburgoLogo from "../../Assets/login/myburgoLogo.svg";
import phoneMockup from "../../Assets/login/phonemockup.svg";
import playstore from "../../Assets/login/googleplay.svg";
import appstore from "../../Assets/login/applestore.svg";
import useAuth from "../../Components/Hook/useAuth";
import { useNavigate } from "react-router-dom";

const LoginPage = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  if (isAuthenticated) {
    navigate("/");
  }
  return (
    <div className="login-page-container">
      {/* Left Section - Hidden on Mobile */}
      <div className="left-section">
        <img className="logo" src={myburgoLogo} alt="MyBurgo Logo" />
        <h2>your ultimate parenting partner</h2>
        <div className="phone-mockup-container">
          <img className="phone-mockup" src={phoneMockup} alt="Phone Mockup" />
        </div>
        <div className="app-store-buttons">
          <img
            className="app-store-button"
            src={playstore}
            alt="Google Play Store"
          />
          <img
            className="app-store-button"
            src={appstore}
            alt="Apple App Store"
          />
        </div>
      </div>

      {/* Right Section - Login Card */}
      <div className="right-section">
        <LoginCard />
      </div>
    </div>
  );
};

export default LoginPage;
