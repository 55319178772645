import React, { useState, useEffect, useRef } from "react";
import {
  RightSection,
  Card,
  Button,
  OtpContainer,
  OtpInputContainer,
  ResendOtpLink,
  SuccessMessage,
  Label,
  InputField,
  PayButton,
  ErrorText,
  Select,
} from "../Dialog/PaymentDialogStyles";
import { DialogContainer } from "./LoginCardStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  open_api_without_auth,
  open_api_with_user_token,
} from "../../Utils/network";
import LoadingOverlay from "../Dialog/LoadingOverlay";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CustomAlert from "../AlertDialog/CustomAlert";
import GoogleSignIn from "./GoogleSignIn";
import {
  fetchCountryCode,
  validatePhoneNumber,
  validateEmail,
  handleOTPChange,
  handleKeyDown,
  handlePhoneNumberChange,
  handleEmailChange,
  requestOTP,
  validateOTP,
  addAlert,
  removeAlert,
  fetchProfileTypes,
} from "../Dialog/helper";
import { useNavigate } from "react-router-dom";
import useAuth from "../Hook/useAuth";
import { useLocation } from "react-router-dom";

const LoginCard = ({ handleLoginSuccess }) => {
  const [useEmail, setUseEmail] = useState(false);
  const [contactValue, setContactValue] = useState("");
  const [isVerified, setVerified] = useState(false);
  const [otpRequested, setOtpRequested] = useState(false);
  const [otp, setOtp] = useState(Array(4).fill(""));
  const [otpValidated, setOtpValidated] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [firstNameError, setfirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);
  const [countryCode, setCountryCode] = useState("in"); // Default to India
  const inputRefs = useRef([]);
  const [bearerToken, setBearerToken] = useState("");
  const [userId, setUserId] = useState(0);
  const [alerts, setAlerts] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [resentOTP, setResentOTP] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [profileType, setProfileType] = useState("");
  const [profileTypeId, setProfileTypeId] = useState(0);
  const [profileTypeError, setProfileTypeError] = useState(false);
  const [profileTypes, setProfileTypes] = useState([]);
  const [userName, setUserName] = useState("");
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();
  const location = useLocation();

  const from = location.state?.from?.pathname || location.pathname;

  useEffect(() => {
    const getCountryCode = async () => {
      const countryCode = await fetchCountryCode();
      setCountryCode(countryCode);
    };
    getCountryCode();
  }, [countryCode]);

  useEffect(() => {
    if (countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (countdown === 0 && resentOTP) {
      setResentOTP(false);
    }
  }, [countdown, resentOTP]);

  useEffect(() => {
    const fetchTypes = async () => {
      await fetchProfileTypes(bearerToken, setProfileTypes);
    };
    fetchTypes();
  }, [bearerToken]);

  const handleAddAlert = (title, message, type, autoClose = false) => {
    addAlert(title, message, type, autoClose, setAlerts);
  };

  const handleRemoveAlert = (id) => {
    removeAlert(id, setAlerts);
  };

  const handleProfileTypeChange = (e) => {
    const selectedId = e.target.value;
    const selectedType = profileTypes.find(
      (type) => type.id.toString() === selectedId
    );
    if (selectedType) {
      setProfileType(selectedType.title);
      setProfileTypeId(selectedType.id);
    } else {
      setProfileType("");
    }
    setProfileTypeError(false);
  };

  const handleToggle = () => {
    setUseEmail(!useEmail);
    setContactValue("");
  };

  const handleRequestOTP = () => {
    setEmailError(false);
    setPhoneNumberError(false);
    if (
      useEmail
        ? validateEmail(email, setEmailError, setShowAlert, handleAddAlert)
        : validatePhoneNumber(
            phoneNumber,
            setPhoneNumberError,
            setShowAlert,
            handleAddAlert
          )
    ) {
      requestOTP(phoneNumber, setOtpRequested, setShowAlert, handleAddAlert);
    }
  };

  const handleSubmitOTP = async () => {
    await handleValidateOTP();
  };

  const handleResendOTP = () => {
    handleRequestOTP();
    setResentOTP(true);
    setCountdown(30);
  };

  const handlePhoneInputChange = (value, data) => {
    handlePhoneNumberChange(
      value,
      data,
      setCountryCode,
      setPhoneNumber,
      setPhoneNumberError
    );
  };

  const handleEmailInputChange = (e) => {
    handleEmailChange(e, setEmail, setEmailError);
  };

  const handlePhoneNumberInputChange = (e) => {
    if (e.target.name === "phoneNumber") {
      setPhoneNumberError(false);
    }
    setPhoneNumber(e.target.value);
  };

  const handlefirstNameChange = (e) => {
    if (e.target.name === "firstName") {
      setfirstNameError(false);
    }
    setfirstName(e.target.value);
  };

  const handlelastNameChange = (e) => {
    if (e.target.name === "lastName") {
      setLastNameError(false);
    }
    setLastName(e.target.value);
  };

  const handleValidateOTP = async () => {
    await validateOTP(
      otp,
      phoneNumber,
      setOtpValidated,
      setVerified,
      setUserId,
      setBearerToken,
      setShowAlert,
      handleAddAlert,
      setfirstName,
      setLastName,
      setEmail,
      setUserName
    );
  };

  const sumbitUserDetails = async () => {
    let registerAsId, title;
    switch (profileType.toLowerCase()) {
      case "mom":
        title = "Mrs";
        break;
      case "dad":
        title = "Mr";
        break;
      case "grand pa":
        title = "Mr";
        break;
      case "grand ma":
        title = "Mrs";
        break;
      default:
        title = "Mr/Mrs";
    }

    const userDetails = {
      id: userId,
      phone: phoneNumber,
      first_name: firstName,
      last_name: lastName,
      email: email,
      register_as_id: profileTypeId,
      title: title,
    };
    try {
      const res = await open_api_with_user_token(bearerToken).patch(
        `/profiles/update_profile/`,
        userDetails
      );

      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        localStorage.setItem("userName", firstName + " " + lastName);
        setIsAuthenticated(true);
        return true;
      } else if (res?.status === 400) {
        if (res?.data?.email != null) {
          setShowAlert(true);
          handleAddAlert("Input Error", res?.data?.email, "error", true);
          setIsLoading(false);
          return false;
        }
      }
      return false;
    } catch (err) {
      console.error("Error in sumbitUserDetails:", err);
      return false;
    }
  };

  const handleOTPInputChange = (e, index) => {
    handleOTPChange(e, index, otp, setOtp, inputRefs);
  };

  const handleOTPKeyDown = (e, index) => {
    handleKeyDown(e, index, otp, inputRefs);
  };

  const updateProfileAndLogin = async () => {
    let valid = true;

    if (!isVerified) {
      if (!firstName) {
        setfirstNameError(true);
        valid = false;
      } else {
        setfirstNameError(false);
      }

      if (!lastName) {
        setLastNameError(true);
        valid = false;
      } else {
        setLastNameError(false);
      }

      if (useEmail) {
        if (!phoneNumber) {
          setPhoneNumberError(true);
          valid = false;
        } else {
          setPhoneNumberError(false);
        }
      } else {
        if (!phoneNumber) {
          setPhoneNumberError(true);
          valid = false;
        } else {
          setPhoneNumberError(false);
        }

        if (!email) {
          setEmailError(true);
          valid = false;
        } else {
          setEmailError(false);
        }
      }

      if (!profileType) {
        setProfileTypeError(true);
        valid = false;
      }
    }

    if (valid) {
      setIsLoading(true);
      try {
        let userDetailsUpdated = false;
        if (!isVerified) {
          userDetailsUpdated = await sumbitUserDetails();
        }
        if (isVerified || userDetailsUpdated) {
          setShowAlert(true);
          handleAddAlert(
            "Login Successful",
            "You have successfully logged in",
            "success",
            true
          );
          if (from === location.pathname) {
            window.location.reload(); // Reload the current page
          } else {
            navigate(from, { replace: true });
          }
        } else {
          setShowAlert(true);
          handleAddAlert(
            "Login failed",
            "There was an error during login",
            "error",
            true
          );
        }
      } catch (error) {
        console.error("Error in flow:", error);
      }
    } else {
      setShowAlert(true);
      handleAddAlert(
        "Input Error",
        "Please fill in all mandatory fields before proceeding",
        "error",
        true
      );
    }
  };

  const handleGoogleSignInSuccess = (idToken) => {
    // Use the idToken for backend authentication
    setUseEmail(true);
    getProfileDetailsWithToken(idToken); // Make sure this function is defined
  };

  const handleGoogleSignInFailure = (error) => {
    console.error(error);
  };

  const getProfileDetailsWithToken = async (idToken) => {
    const localData = {
      token: idToken,
    };

    try {
      const res = await open_api_without_auth.post(
        `/auth/google/oauth/`,
        localData
      );
      if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
        if (res?.data?.data?.user != null) {
          setOtpValidated(true);
          const verified = res?.data?.data?.verified;
          if (verified === true) {
            setVerified(true);
          }
          const userDetailsId = res?.data?.data?.user?.id;
          const accessToken = res?.data?.data?.token?.access;
          setUserId(userDetailsId);
          setBearerToken(accessToken);
        }
        return isVerified;
      } else {
        setShowAlert(true);
        handleAddAlert("Login Error", res?.data?.data?.message, "error", true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isLoading && <LoadingOverlay />}
      <DialogContainer>
        <RightSection>
          <h1>Login</h1>
          <Card>
            {otpValidated ? (
              <>
                <SuccessMessage>
                  <CheckCircleIcon color="success" />
                  <h3>
                    Your {useEmail ? "email" : "phone number"}{" "}
                    {useEmail ? email : `+91 ${phoneNumber}`} has been verified
                    successfully
                  </h3>
                </SuccessMessage>
                {!isVerified ? (
                  <div>
                    <Label>First Name:</Label>
                    <InputField
                      name="firstName"
                      type="text"
                      placeholder="Enter your first name"
                      value={firstName}
                      onChange={handlefirstNameChange}
                      style={{
                        borderColor: firstNameError ? "red" : "#ccc",
                      }}
                    />
                    {firstNameError && (
                      <ErrorText>This field is required</ErrorText>
                    )}
                    <Label>Last Name:</Label>
                    <InputField
                      name="lastName"
                      type="text"
                      placeholder="Enter your last name"
                      value={lastName}
                      onChange={handlelastNameChange}
                      style={{
                        borderColor: lastNameError ? "red" : "#ccc",
                      }}
                    />
                    {lastNameError && (
                      <ErrorText>This field is required</ErrorText>
                    )}
                    {useEmail ? (
                      <>
                        <Label>Phone Number:</Label>
                        <InputField
                          name="phoneNumber"
                          type="number"
                          placeholder="Please enter your phone number"
                          value={phoneNumber}
                          onChange={handlePhoneNumberInputChange}
                          style={{
                            borderColor: phoneNumberError ? "red" : "#ccc",
                          }}
                        />
                        {phoneNumberError && (
                          <ErrorText>This field is required</ErrorText>
                        )}
                      </>
                    ) : (
                      <>
                        <Label>Email:</Label>
                        <InputField
                          name="email"
                          type="email"
                          placeholder="Enter your email id"
                          value={email}
                          onChange={handleEmailInputChange}
                          style={{
                            borderColor: emailError ? "red" : "#ccc",
                          }}
                        />
                        {emailError && (
                          <ErrorText>This field is required</ErrorText>
                        )}
                      </>
                    )}
                    <Label htmlFor="profileType">Select Profile Type:</Label>
                    <Select
                      id="profileType"
                      name="profileType"
                      value={
                        profileTypes.find((type) => type.title === profileType)
                          ?.id || ""
                      }
                      onChange={handleProfileTypeChange}
                      style={{
                        borderColor: profileTypeError ? "red" : "#ccc",
                      }}
                    >
                      <option value="">Select a profile type</option>
                      {profileTypes.map((type) => (
                        <option key={type.id} value={type.id}>
                          {type.title}
                        </option>
                      ))}
                    </Select>
                    {profileTypeError && (
                      <ErrorText>This field is required</ErrorText>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </>
            ) : otpRequested ? (
              <>
                <h3>
                  A 4-digit code is sent to{" "}
                  {useEmail ? contactValue : `+91 ${phoneNumber}`}
                </h3>
                <OtpInputContainer>
                  {Array.from({ length: 4 }).map((_, index) => (
                    <input
                      key={index}
                      type="text"
                      maxLength="1"
                      value={otp[index]}
                      onChange={(e) => handleOTPInputChange(e, index)}
                      onKeyDown={(e) => handleOTPKeyDown(e, index)}
                      ref={(el) => (inputRefs.current[index] = el)}
                    />
                  ))}
                </OtpInputContainer>
                <Button onClick={handleSubmitOTP}>Submit</Button>
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                  {resentOTP ? (
                    <div>
                      <p>Resend OTP in {countdown} seconds</p>
                    </div>
                  ) : (
                    <div>
                      <ResendOtpLink onClick={handleResendOTP}>
                        Resend OTP
                      </ResendOtpLink>
                      <p style={{ marginTop: "8px", textAlign: "center" }}>
                        Didn't receive a code? Click on Resend to receive OTP
                      </p>
                    </div>
                  )}
                </div>
              </>
            ) : (
              <>
                {useEmail ? (
                  <>
                    <h3>Login with Google</h3>
                    <GoogleSignIn
                      onSignInSuccess={handleGoogleSignInSuccess}
                      onSignInFailure={handleGoogleSignInFailure}
                    />
                    <Button onClick={handleRequestOTP}>Request OTP</Button>
                    <p style={{ marginTop: "8px", textAlign: "center" }}>
                      Or,{" "}
                      <a
                        href="#"
                        style={{ color: "#1a73e8" }}
                        onClick={handleToggle}
                      >
                        Verify via phone OTP
                      </a>
                    </p>
                  </>
                ) : (
                  <>
                    <h3>Verify using Phone Number</h3>
                    <OtpContainer
                      style={{
                        borderColor: phoneNumberError ? "red" : "#ccc",
                      }}
                    >
                      <PhoneInput
                        country={"in"}
                        value={phoneNumber}
                        onChange={handlePhoneInputChange}
                        onlyCountries={["in"]}
                        disableDropdown
                        disableCountryCode
                        placeholder="Enter your phone number"
                        inputStyle={{
                          border: "none",
                          outline: "none",
                          padding: "8px 8px 8px 44px",
                          flex: "1",
                          borderRadius: "0 8px 8px 0",
                          overflow: "auto",
                          width: "100%",
                        }}
                      />
                    </OtpContainer>
                    {phoneNumberError && (
                      <ErrorText>Please enter a valid phone number</ErrorText>
                    )}
                    <Button onClick={handleRequestOTP}>Request OTP</Button>
                  </>
                )}
              </>
            )}
          </Card>

          {otpValidated ? (
            <PayButton className="payButton" onClick={updateProfileAndLogin}>
              Login
            </PayButton>
          ) : (
            <></>
          )}
        </RightSection>

        {showAlert &&
          alerts.map((alert) => (
            <CustomAlert
              key={alert.id}
              title={alert.title}
              message={alert.message}
              onClose={() => handleRemoveAlert(alert.id)}
              autoClose={alert.autoClose}
              type={alert.type}
            />
          ))}
      </DialogContainer>
    </>
  );
};

export default LoginCard;
