import React, { useState } from "react";
import styled from "styled-components";
import { usePopup } from "./PopupContext"; // Adjust the import path
import { open_api } from "../../Utils/network";

// Import your assets
import contentBackground from "../../Assets/popup/designCurve.svg"; // Adjust the path
import logo from "../../Assets/popup/myburgoLogo.svg"; // Adjust the path
import closeIcon from "../../Assets/popup/closeIcon.svg"; // Adjust the path
import backgroundImage from "../../Assets/popup/backgroundImage2.png"; // Adjust the path

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Dialog = styled.div`
  position: relative;
  width: 900px;
  height: 600px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    width: 90%;
    height: 90%;
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  width: 72%;
  height: 100%;
  background-image: url(${backgroundImage});
  background-size: cover;
  background-position: center;
  margin-right: 57%;

  @media (max-width: 768px) {
    width: 100%;
    height: 30%;
    margin-right: 0;
  }
`;

const ContentContainer = styled.div`
  position: absolute;
  top: 51%;
  left: 72%;
  transform: translate(-50%, -50%);
  background: url(${contentBackground}) no-repeat center center;
  background-size: cover;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 62%;
  height: 100%;
  border-radius: 10px;

  @media (max-width: 768px) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    width: 100%;
    height: 70%;
    border-radius: 0 0 10px 10px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
  background-image: url(${closeIcon});
  background-size: contain;
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  z-index: 10;

  @media (max-width: 768px) {
    top: 15px;
    right: 15px;
  }
`;

const Logo = styled.div`
  width: 43%;
  height: 15%;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    width: 60%;
    height: 10%;
    margin-bottom: 10px;
  }
`;

const Title = styled.div`
  font-size: 25px;
  margin-bottom: 20px;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const Form = styled.form`
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 73%;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
  }
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background: #f27497;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: medium;
  font-weight: 700;
`;

const PopupDialog = ({ type }) => {
  const { isOpen, hidePopup } = usePopup();
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    phone: null,
    type: type,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    open_api
      .post("home/earlyinterest/", userDetails)
      .then((res) => {
        if (res?.status === 200 || res?.status === 201 || res?.status === 204) {
          setUserDetails({
            name: "",
            email: "",
            phone: null,
          });
          // Trigger the book download
          const link = document.createElement("a");
          link.href = "/handling-kids-tantrums-practical-tips.pdf"; // Path to your book in the public folder
          link.download = "handling-kids-tantrums-practical-tips.pdf";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          hidePopup();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClose = () => {
    hidePopup();
    sessionStorage.setItem("popupSeen", "true");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return isOpen ? (
    <Overlay>
      <Dialog>
        <CloseButton onClick={handleClose} />
        <ImageContainer />
        <ContentContainer>
          <Logo />
          <Title>Download free ebook on "Handling kids tantrums"</Title>
          <Form onSubmit={handleSubmit}>
            <Input
              type="text"
              name="name"
              placeholder="Name"
              value={userDetails.name}
              onChange={handleChange}
              required
            />
            <Input
              type="email"
              name="email"
              placeholder="Email"
              value={userDetails.email}
              onChange={handleChange}
              required
            />
            <Input
              type="tel"
              name="phone"
              placeholder="Phone Number"
              value={userDetails.phone}
              onChange={handleChange}
              required
            />
            <Button type="submit">Download the ebook</Button>
          </Form>
        </ContentContainer>
      </Dialog>
    </Overlay>
  ) : null;
};

export default PopupDialog;
