import React, { useState, useEffect } from "react";
import {
  Dialog as MuiDialog,
  DialogContent,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import "./ConfirmationDialog.css";
import { useNavigate } from "react-router-dom";
import successAnimation from "../../Assets/lottie/successlottie.json";
import failureAnimation from "../../Assets/lottie/failureLottie.json";
import Lottie from "react-lottie-player";

const ConfirmationDialog = ({
  open,
  onClose,
  image,
  title,
  description,
  status,
  statusText,
  statusDescription,
  statusIcon,
  backgroundColor,
  redirect,
  countdownDuration,
  whatsappLink,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // State to manage the countdown timer
  const [countdown, setCountdown] = useState(countdownDuration);

  useEffect(() => {
    if (open) {
      if (status === "success") {
        if (whatsappLink == null) {
          const intervalId = setInterval(() => {
            setCountdown((prevCountdown) => {
              if (prevCountdown > 1) {
                return prevCountdown - 1;
              } else {
                clearInterval(intervalId);
                navigate(redirect);
                return 0;
              }
            });
          }, 1000);

          return () => clearInterval(intervalId);
        }
      }
    }
  }, [open, status, whatsappLink, redirect, navigate]);

  const lottieAnimation =
    status === "success" ? successAnimation : failureAnimation;

  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogContent
        className="confirmation-dialog"
        style={{ backgroundColor }}
      >
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
            zIndex: 2,
          }}
        >
          <CloseIcon />
        </IconButton>
        <div className="confirmation-dialog-content">
          <Lottie
            loop={false}
            play
            animationData={lottieAnimation}
            style={{ height: 150 }}
          />
          <h2>{statusText}</h2>
          <p className="confirmation-text">{statusDescription}</p>
          {status != "failure" && whatsappLink != null ? (
            <>
              <p className="countdown-text">
                Join the WhatsApp group using the link below:
              </p>
              <a
                href={whatsappLink}
                target="_blank"
                rel="noopener noreferrer"
                className="link-button-wrapper"
              >
                <button className="link-button">Join now</button>
              </a>
            </>
          ) : (
            <>
              {status != "failure" ? (
                <>
                  <p className="countdown-text">
                    Redirecting to mylearning page in {countdown} seconds...
                  </p>
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <div className="course-card">
            <img src={image} alt={title} className="course-image" />
            <div className="course-details">
              <h3>{title}</h3>
              <p>{description}</p>
            </div>
          </div>
        </div>
      </DialogContent>
    </MuiDialog>
  );
};

export default ConfirmationDialog;
